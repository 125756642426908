<template>
    <div class="attendance-view-fields">
        <b-table-simple>
            <b-tbody>
                <b-tr v-if="task.project_id">
                    <b-th class="text-right">所属案件</b-th>
                    <b-td>{{ task.project_name }}</b-td>
                </b-tr>
                <b-tr v-if="task.org_id">
                    <b-th class="text-right">所属組織</b-th>
                    <b-td>{{ task.org_full_name }} </b-td>
                </b-tr>
                <b-tr>
                    <b-th class="text-right">申請者</b-th>
                    <b-td>{{ task.employee_name }} ({{ task.employee_code}})</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-tabs>
            <b-tab title="現場勤怠">
                <div style="margin-top:1.5rem;">
                    <div class="pl-lg-4">
                        <b-row>
                            <b-col md="2" lg="1">{{ fields_value['year'] }}年</b-col>
                            <b-col md="2" lg="1">{{ fields_value['month'] }}月</b-col>
                            <b-col md="4" lg="3" class="ml20" v-if="fields_value['basic_attendance_day']">基準出勤日数：{{
                                fields_value['basic_attendance_day'] }}</b-col>
                        </b-row>
                    </div>
                    <hr class="my-4">
                    <div class="pl-lg-4 setting-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">基本就業時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.work_start_time_2 }} ～ {{ fields_value.work_end_time_2 }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間1</b-th>
                                    <b-td class="col-sm-4">
                                        <div
                                            v-if="fields_value[`break_start_time_1_2`] || fields_value[`break_end_time_1_2`]">
                                            {{ fields_value[`break_start_time_1_2`] }} ～ {{
                                                fields_value[`break_end_time_1_2`] }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">早出時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.early_start_time_2 }} ～ {{ fields_value.early_end_time_2 }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間2</b-th>
                                    <b-td class="col-sm-4">
                                        <div
                                            v-if="fields_value[`break_start_time_2_2`] || fields_value[`break_end_time_2_2`]">
                                            {{ fields_value[`break_start_time_2_2`] }} ～ {{
                                                fields_value[`break_end_time_2_2`] }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">残業時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.start_overtime_2 }} ～ {{ fields_value.end_overtime_2 }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間3</b-th>
                                    <b-td class="col-sm-4">
                                        <div
                                            v-if="fields_value[`break_start_time_3_2`] || fields_value[`break_end_time_3_2`]">
                                            {{ fields_value[`break_start_time_3_2`] }} ～ {{
                                                fields_value[`break_end_time_3_2`] }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">早朝・深夜就業時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.late_start_time_2 }} ～ {{ fields_value.late_end_time_2 }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間4</b-th>
                                    <b-td class="col-sm-4">
                                        <div
                                            v-if="fields_value[`break_start_time_4_2`] || fields_value[`break_end_time_4_2`]">
                                            {{ fields_value[`break_start_time_4_2`] }} ～ {{
                                                fields_value[`break_end_time_4_2`] }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">お昼休み時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.lunch_start_time_2 }} ～ {{ fields_value.lunch_end_time_2 }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間5</b-th>
                                    <b-td class="col-sm-4">
                                        <div
                                            v-if="fields_value[`break_start_time_5_2`] || fields_value[`break_end_time_5_2`]">
                                            {{ fields_value[`break_start_time_5_2`] }} ～ {{
                                                fields_value[`break_end_time_5_2`] }}
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div class="pl-lg-4 duty-container mt20">
                        <table class="table b-table table-sm b-table-stacked-md">
                            <thead>
                                <tr>
                                    <th>日</th>
                                    <th>曜日</th>
                                    <th>区分</th>
                                    <th>出社時刻</th>
                                    <th>退社時刻</th>
                                    <th>開始時刻</th>
                                    <th>終了時刻</th>
                                    <th>基本就業時間</th>
                                    <th>早出<br>残業</th>
                                    <th>早朝<br>深夜</th>
                                    <th>合計</th>
                                    <!-- <th v-if="task.employee !== user.me.employee">見直し</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(day, index) in calendarDays">
                                    <tr :key="index" :style="{ 'background-color': applyBackgroundColor(day, 2) }">
                                        <td>{{ day }}</td>
                                        <td>{{ weekday(day) }}</td>
                                        <td>
                                            {{ showTypeText(day, 2) }}
                                        </td>
                                        <td>
                                            {{ fields_value[`start_time_${day}_2`] }}
                                        </td>
                                        <td>
                                            {{ fields_value[`end_time_${day}_2`] }}
                                        </td>
                                        <td>{{ fields_value[`base_start_time_${day}_2`] }}</td>
                                        <td>{{ fields_value[`base_end_time_${day}_2`] }}</td>
                                        <td>{{ fields_value[`base_time_${day}_2`] }}</td>
                                        <td>{{ fields_value[`over_${day}_2`] }}</td>
                                        <td>{{ fields_value[`night_over_${day}_2`] }}</td>
                                        <td>{{ fields_value[`total_time_${day}_2`] }}</td>
                                        <!-- <td v-if="task.employee !== user.me.employee">
                                        <AttendanceControlCreator :field='get_field_by_name(`review_time_${day}`)' :error='get_error_by_name(`review_time_${day}`)' v-model="fields_value[`review_time_${day}`]" :data="fields_value" @blur="roundDownTime" @input="updateValue" />
                                    </td> -->
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="pl-lg-4 total-day-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="text-right">出勤日数</b-th>
                                    <b-td>{{ fields_value['attendance_days_2'] }}</b-td>
                                    <b-th class="text-right">出勤日数算出</b-th>
                                    <b-td>{{ fields_value['attendance_time_2'] }}</b-td>
                                    <b-th class="text-right">総就業時間</b-th>
                                    <b-td>{{ fields_value['total_work_time_2'] }}</b-td>
                                    <b-th class="text-right">普通残業時間</b-th>
                                    <b-td>{{ fields_value['total_early_time_2'] }}</b-td>
                                    <b-th class="text-right">普通残業時間算出</b-th>
                                    <b-td>{{ fields_value['over_time_2'] }}</b-td>
                                    <b-th class="text-right">深夜残業時間</b-th>
                                    <b-td>{{ fields_value['total_night_time_2'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">法定休日<br>出勤日数</b-th>
                                    <b-td>{{ fields_value['legal_holiday_2'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>出勤時間</b-th>
                                    <b-td>{{ fields_value['legal_holiday_time_2'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>残業時間</b-th>
                                    <b-td>{{ fields_value['legal_hol_over_2'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>深夜残業時間</b-th>
                                    <b-td>{{ fields_value['legal_hol_night_2'] }}</b-td>
                                    <b-th class="text-right">代休取得日数</b-th>
                                    <b-td>{{ fields_value['compensatory_days_2'] }}</b-td>
                                    <b-th class="text-right">振替取得日数</b-th>
                                    <b-td>{{ fields_value['compensatory_leave_2'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">法定外休日<br>出勤日数</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_2'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>出勤時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_time_2'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>残業時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_over_2'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>深夜残業時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_night_2'] }}</b-td>
                                    <b-th class="text-right">有休取得日数</b-th>
                                    <b-td>{{ fields_value['paid_vacation_days_2'] }}</b-td>
                                    <b-th class="text-right">有休時間</b-th>
                                    <b-td>{{ fields_value['paid_vacation_time_2'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">欠勤日数</b-th>
                                    <b-td>{{ fields_value['absence_days_2'] }}</b-td>
                                    <b-th class="text-right">欠勤日数算出 </b-th>
                                    <b-td>{{ fields_value['absence_calculate_2'] }}</b-td>
                                    <b-th class="text-right">欠勤時間</b-th>
                                    <b-td>{{ fields_value['absence_time_2'] }}</b-td>
                                    <b-th class="text-right">超過残業時間(60時間)</b-th>
                                    <b-td>{{ fields_value['excess_over_2'] }}</b-td>
                                    <b-th class="text-right">総合計時間</b-th>
                                    <b-td>{{ fields_value['amount_time_2'] }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div v-if="fields_value.work_time_file" class="pl-lg-4 total-day-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">勤務表ファイル</b-th>
                                    <b-td style="border: 1px solid #e9ecef; border-left:0;">
                                        <a href="javascript:void(0)" @click="previewFile(fields_value.work_time_file)">{{
                                            fields_value.attachment_name }}</a>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div v-if="fields_value.remark_2" class="pl-lg-4 total-day-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">備考</b-th>
                                    <b-td style="border: 1px solid #e9ecef; border-left:0;">
                                        {{ fields_value.remark_2 }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </div>
            </b-tab>
            <b-tab title="社内勤怠">
                <div style="margin-top:1.5rem;">
                    <div class="pl-lg-4">
                        <b-row>
                            <b-col md="2" lg="1">{{ fields_value['year'] }}年</b-col>
                            <b-col md="2" lg="1">{{ fields_value['month'] }}月</b-col>
                            <b-col md="4" lg="3" class="ml20" v-if="fields_value['basic_attendance_day']">基準出勤日数：{{
                                fields_value['basic_attendance_day'] }}</b-col>
                        </b-row>
                    </div>
                    <hr class="my-4">
                    <div class="pl-lg-4 setting-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">基本就業時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.work_start_time }} ～ {{ fields_value.work_end_time }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間1</b-th>
                                    <b-td class="col-sm-4">
                                        <div v-if="fields_value[`break_start_time_1`] || fields_value[`break_end_time_1`]">
                                            {{ fields_value[`break_start_time_1`] }} ～ {{ fields_value[`break_end_time_1`]
                                            }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">早出時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.early_start_overtime }} ～ {{ fields_value.early_end_overtime }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間2</b-th>
                                    <b-td class="col-sm-4">
                                        <div v-if="fields_value[`break_start_time_2`] || fields_value[`break_end_time_2`]">
                                            {{ fields_value[`break_start_time_2`] }} ～ {{ fields_value[`break_end_time_2`]
                                            }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">残業時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.start_overtime }} ～ {{ fields_value.end_overtime }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間3</b-th>
                                    <b-td class="col-sm-4">
                                        <div v-if="fields_value[`break_start_time_3`] || fields_value[`break_end_time_3`]">
                                            {{ fields_value[`break_start_time_3`] }} ～ {{ fields_value[`break_end_time_3`]
                                            }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">早朝・深夜就業時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.late_start_time }} ～ {{ fields_value.late_end_time }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間4</b-th>
                                    <b-td class="col-sm-4">
                                        <div v-if="fields_value[`break_start_time_4`] || fields_value[`break_end_time_4`]">
                                            {{ fields_value[`break_start_time_4`] }} ～ {{ fields_value[`break_end_time_4`]
                                            }}
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">お昼休み時間</b-th>
                                    <b-td class="col-sm-4">
                                        {{ fields_value.lunch_start_time }} ～ {{ fields_value.lunch_end_time }}
                                    </b-td>
                                    <b-th class="col-sm-2 text-right">休憩時間5</b-th>
                                    <b-td class="col-sm-4">
                                        <div v-if="fields_value[`break_start_time_5`] || fields_value[`break_end_time_5`]">
                                            {{ fields_value[`break_start_time_5`] }} ～ {{ fields_value[`break_end_time_5`]
                                            }}
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div class="pl-lg-4 duty-container mt20">
                        <table class="table b-table table-sm b-table-stacked-md">
                            <thead>
                                <tr>
                                    <th>日</th>
                                    <th>曜日</th>
                                    <th>区分</th>
                                    <th>出社時刻</th>
                                    <th>退社時刻</th>
                                    <th>開始時刻</th>
                                    <th>終了時刻</th>
                                    <th>基本就業時間</th>
                                    <th>早出<br>残業</th>
                                    <th>早朝<br>深夜</th>
                                    <th>合計</th>
                                    <!-- <th v-if="task.employee !== user.me.employee">見直し</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(day, index) in calendarDays">
                                    <!-- <tr :key="index" v-if="task.employee === user.me.employee && fields_value[`review_time_${day}`]" style="border: 2px dotted #1da1f2;" :style="{'background-color': applyBackgroundColor(day)}">-->
                                    <!-- <tr :key="index" v-if="task.employee === user.me.employee && fields_value[`review_time_${day}`]" :style="{'background-color': applyBackgroundColor(day)}"> -->
                                    <tr :key="index" :style="{ 'background-color': applyBackgroundColor(day, 1) }">
                                        <td>{{ day }}</td>
                                        <td>{{ weekday(day) }}</td>
                                        <td>
                                            {{ showTypeText(day, 1) }}
                                        </td>
                                        <td>
                                            {{ fields_value[`start_time_${day}`] }}
                                        </td>
                                        <td>
                                            {{ fields_value[`end_time_${day}`] }}
                                        </td>
                                        <td>{{ fields_value[`basic_start_time_${day}`] }}</td>
                                        <td>{{ fields_value[`basic_end_time_${day}`] }}</td>
                                        <td>{{ fields_value[`basic_time_${day}`] }}</td>
                                        <td>{{ fields_value[`over_${day}`] }}</td>
                                        <td>{{ fields_value[`night_over_${day}`] }}</td>
                                        <td>{{ fields_value[`total_time_${day}`] }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="pl-lg-4 total-day-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="text-right">出勤日数</b-th>
                                    <b-td>{{ fields_value['attendance_days'] }}</b-td>
                                    <b-th class="text-right">出勤日数算出</b-th>
                                    <b-td>{{ fields_value['attendance_time'] }}</b-td>
                                    <b-th class="text-right">総就業時間</b-th>
                                    <b-td>{{ fields_value['total_work_time'] }}</b-td>
                                    <b-th class="text-right">普通残業時間</b-th>
                                    <b-td>{{ fields_value['total_early_overtime'] }}</b-td>
                                    <b-th class="text-right">普通残業時間算出</b-th>
                                    <b-td>{{ fields_value['over_time'] }}</b-td>
                                    <b-th class="text-right">深夜残業時間</b-th>
                                    <b-td>{{ fields_value['total_night_overtime'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">法定休日<br>出勤日数</b-th>
                                    <b-td>{{ fields_value['legal_holiday'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>出勤時間</b-th>
                                    <b-td>{{ fields_value['legal_holiday_time'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>残業時間</b-th>
                                    <b-td>{{ fields_value['legal_hol_over'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>深夜残業時間</b-th>
                                    <b-td>{{ fields_value['legal_hol_night'] }}</b-td>
                                    <b-th class="text-right">代休取得日数</b-th>
                                    <b-td>{{ fields_value['compensatory_days'] }}</b-td>
                                    <b-th class="text-right">振替取得日数</b-th>
                                    <b-td>{{ fields_value['compensatory_leave'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">法定外休日<br>出勤日数</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>出勤時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_time'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>残業時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_over'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>深夜残業時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_night'] }}</b-td>
                                    <b-th class="text-right">有休取得日数</b-th>
                                    <b-td>{{ fields_value['paid_vacation_days'] }}</b-td>
                                    <b-th class="text-right">有休時間</b-th>
                                    <b-td>{{ fields_value['paid_vacation_time'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">欠勤日数</b-th>
                                    <b-td>{{ fields_value['absence_days'] }}</b-td>
                                    <b-th class="text-right">欠勤日数算出 </b-th>
                                    <b-td>{{ fields_value['absence_calculate'] }}</b-td>
                                    <b-th class="text-right">欠勤時間</b-th>
                                    <b-td>{{ fields_value['absence_time'] }}</b-td>
                                    <b-th class="text-right">超過残業時間(60時間)</b-th>
                                    <b-td>{{ fields_value['excess_over'] }}</b-td>
                                    <b-th class="text-right">総合計時間</b-th>
                                    <b-td>{{ fields_value['amount_time'] }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div v-if="fields_value.remark" class="pl-lg-4 total-day-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="col-sm-2 text-right">備考</b-th>
                                    <b-td style="border: 1px solid #e9ecef; border-left:0;">
                                        {{ fields_value.remark }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </div>
            </b-tab>
            <b-tab title="合併勤怠">
                <div style="margin-top:1.5rem;">
                    <div class="pl-lg-4">
                        <b-row>
                            <b-col md="2" lg="1">{{ fields_value['year'] }}年</b-col>
                            <b-col md="2" lg="1">{{ fields_value['month'] }}月</b-col>
                            <b-col md="4" lg="3" class="ml20" v-if="fields_value['basic_attendance_day']">基準出勤日数：{{
                                fields_value['basic_attendance_day'] }}</b-col>
                        </b-row>
                    </div>
                    <hr class="my-4">
                    <div class="pl-lg-4 duty-container mt20">
                        <table class="table b-table table-sm b-table-stacked-md">
                            <thead>
                                <tr>
                                    <th>日</th>
                                    <th>曜日</th>
                                    <th>現場区分</th>
                                    <th>社内区分</th>
                                    <th>出社時刻</th>
                                    <th>退社時刻</th>
                                    <th>基本就業時間</th>
                                    <th>中抜時間</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(day, index) in calendarDays">
                                    <tr :key="index" :style="{ 'background-color': applyWeekendColor(day) }">
                                        <td>{{ day }}</td>
                                        <td>{{ weekday(day) }}</td>
                                        <td>{{ showTypeText(day, 2) }}</td>
                                        <td>{{ showTypeText(day, 1) }}</td>
                                        <td>{{ fields_value[`start_time_${day}_3`] }}</td>
                                        <td>{{ fields_value[`end_time_${day}_3`] }}</td>
                                        <td>{{ fields_value[`basic_time_${day}_3`] }}</td>
                                        <td>{{ fields_value[`step_out_time_${day}`] }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="pl-lg-4 total-day-container mt20">
                        <b-table-simple>
                            <b-tbody>
                                <b-tr>
                                    <b-th class="text-right">出勤日数</b-th>
                                    <b-td>{{ fields_value['attendance_days_3'] }}</b-td>
                                    <b-th class="text-right">出勤日数算出</b-th>
                                    <b-td>{{ fields_value['attendance_time_3'] }}</b-td>
                                    <b-th class="text-right">総就業時間</b-th>
                                    <b-td>{{ fields_value['total_work_time_3'] }}</b-td>
                                    <b-th class="text-right">普通残業時間</b-th>
                                    <b-td>{{ fields_value['total_early_time_3'] }}</b-td>
                                    <b-th class="text-right">普通残業時間算出</b-th>
                                    <b-td>{{ fields_value['over_time_3'] }}</b-td>
                                    <b-th class="text-right">深夜残業時間</b-th>
                                    <b-td>{{ fields_value['total_night_time_3'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">法定休日<br>出勤日数</b-th>
                                    <b-td>{{ fields_value['legal_holiday_3'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>出勤時間</b-th>
                                    <b-td>{{ fields_value['legal_holiday_time_3'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>残業時間</b-th>
                                    <b-td>{{ fields_value['legal_hol_over_3'] }}</b-td>
                                    <b-th class="text-right">法定休日<br>深夜残業時間</b-th>
                                    <b-td>{{ fields_value['legal_hol_night_3'] }}</b-td>
                                    <b-th class="text-right">代休取得日数</b-th>
                                    <b-td>{{ fields_value['compensatory_days_3'] }}</b-td>
                                    <b-th class="text-right">振替取得日数</b-th>
                                    <b-td>{{ fields_value['compensatory_leave_3'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">法定外休日<br>出勤日数</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_3'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>出勤時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_time_3'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>残業時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_over_3'] }}</b-td>
                                    <b-th class="text-right">法定外休日<br>深夜残業時間</b-th>
                                    <b-td>{{ fields_value['nonlegal_hol_night_3'] }}</b-td>
                                    <b-th class="text-right">有休取得日数</b-th>
                                    <b-td>{{ fields_value['paid_vacation_days_3'] }}</b-td>
                                    <b-th class="text-right">有休時間</b-th>
                                    <b-td>{{ fields_value['paid_vacation_time_3'] }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th class="text-right">欠勤日数</b-th>
                                    <b-td>{{ fields_value['absence_days_3'] }}</b-td>
                                    <b-th class="text-right">欠勤日数算出 </b-th>
                                    <b-td>{{ fields_value['absence_calculate_3'] }}</b-td>
                                    <b-th class="text-right">欠勤時間</b-th>
                                    <b-td>{{ fields_value['absence_time_3'] }}</b-td>
                                    <b-th class="text-right">超過残業時間(60時間)</b-th>
                                    <b-td>{{ fields_value['excess_over_3'] }}</b-td>
                                    <b-th class="text-right">総合計時間</b-th>
                                    <b-td>{{ fields_value['amount_time_3'] }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </div>
                <b-card-footer>
                    <b-row>
                        <b-col v-if="showEditBtn" cols="12" class="d-flex justify-content-between">
                            <span>
                                <b-button variant="primary" v-b-modal.modal-edit-task @click="_openEditModal">編集</b-button>
                            </span>
                        </b-col>
                        <b-col  v-if="editor" cols="12" class="d-flex justify-content-between">
                            <div class="mg-t-10 ft-14" > {{ editor }} は {{editorTime}}に編集しました。</div>
                        </b-col>

                    </b-row>
                </b-card-footer>
            </b-tab>
        </b-tabs>
        <b-overlay v-if="showEditBtn" rounded="mdd" class="mt-3 ml-4">
            <b-card no-body>
                <b-card-header class="border-0">
                    <h3 class="mb-0 d-inline">休暇履歴</h3>
                </b-card-header>
                <b-table
                    :items="vacations"
                    :fields="vacationFields"
                    stacked="md"
                    show-empty
                    small
                    hover
                >
                    <template #cell(is_start_half)="row">
                        <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                        <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                    </template>
                </b-table>
                <b-card-footer>
                </b-card-footer>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>
import { viewAttachment } from "./api";
import { mapState } from "vuex";
import {ajax, common, config} from "@/utils";
import {helper} from "@/views/common/index";

export default {
    props: {
        task: { type: Object },
        fields: { type: [Array, Object] },
        fields_value: { type: Object },
        error: { type: Object, default: () => ({}) },
        openEditModal: { type: Function },
        editor:{type:String},
        editorTime: {type:String},
        showEditBtn:{type: Boolean, default: false}

    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth(),
            vacations: [],
        };
    },
    mounted() {
        // this.getVacationsMonth(this.fields_value['year'], this.fields_value['month'])
    },
    watch: {
        fields_value: {
            handler: function () {
                this.getVacationsMonth(this.fields_value['year'], this.fields_value['month'])
            },
            deep: true
        }
    },
    computed: {
        ...mapState('account', ['user']),
        calendarDays() {
            const daysInMonth = new Date(
                this.fields_value['year'],
                this.fields_value['month'],
                0
            ).getDate();
            return Array.from({ length: daysInMonth }, (_, i) => i + 1);
        },
        weekday() {
            const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
            return (day) =>
                weekdays[new Date(this.fields_value['year'], this.fields_value['month'] - 1, day).getDay()];
        },
        vacationFields() {
            return [
                { key: 'category_name', label: '休暇種類' },
                { key: 'start_date', label: '開始日' },
                { key: 'end_date', label: '終了日' },
                { key: 'days', label: '日数' },
                { key: 'is_start_half', label: '午後から'}
            ];
        },
    },
    methods: {
        _openEditModal() {
            // 子组件中通过调用父组件传递的方法
            this.openEditModal(true);
        },
        
        previewFile(file_uuid) {
            return viewAttachment(file_uuid, this);
        },
        showTypeText(day, tabType) {
            let typeKey = `type_${day}`;
            //現場勤怠の場合
            if (tabType === 2) {
                typeKey = `type_${day}_2`;
            }
            const type = this.fields_value[typeKey];
            switch (type) {
                case '01':
                    return '出勤';
                case '02':
                    return '欠勤';
                case '03':
                    return '有給';
                case '04':
                    return '代休';
                case '05':
                    return '休日出勤';
                case '06':
                    return '在宅ワーク';
                case '07':
                    return '定休';
                case '08':
                    return '遅刻';
                case '09':
                    return '早退';
                case '10':
                    return '休日';
                case '11':
                    return '振替';
                case '12':
                    return '休出・在宅';
                case '13':
                    return '休出・遅刻';
                case '14':
                    return '休出・早退';
                default:
                    return '';
            }
        },
        roundDownTime(event, name) {
            if (event.target.value) {
                const timeValue = event.target.value;
                const minute = Number(timeValue.split(':')[1]);
                let newValue = timeValue;
                if (minute % 15 !== 0) {
                    const roundedMinute = Math.floor(minute / 15) * 15;
                    const hour = Number(timeValue.split(':')[0]);
                    newValue = `${hour.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')}`;
                    this.fields_value[name] = newValue;
                }
            }
        },
        applyBackgroundColor(day, tabType) {
            let color_code = '';
            let startTime = `start_time_${day}`;
            let endTime = `end_time_${day}`;
            let type = `type_${day}`;
            //現場勤怠の場合
            if (tabType === 2) {
                startTime = `start_time_${day}_2`;
                endTime = `end_time_${day}_2`;
                type = `type_${day}_2`;
            }
            //欠勤、有休、代休の場合
            if (this.fields_value[type] === '02' || this.fields_value[type] === '03' || this.fields_value[type] === '04') {
                if (!this.isDiffHours(this.fields_value[startTime], this.fields_value[endTime])) {
                    color_code = '#E9E9E9';
                } else {
                    color_code = '#F5F5D2';
                }
            }
            //土曜日、日曜日の場合
            if (this.fields_value[`type_${day}`] === null && (this.weekday(day) === '土' || this.weekday(day) === '日')) {
                color_code = '#E9E9E9';
            }
            return color_code;
        },
        applyWeekendColor(day) {
            let color_code = '';
            //土曜日、日曜日の場合
            if ((this.weekday(day) === '土' || this.weekday(day) === '日')) {
                color_code = '#E9E9E9';
            }
            return color_code;
        },
        isDiffHours(start_time, end_time) {
            const start = new Date(`1970/01/01 ${start_time}`);
            const end = new Date(`1970/01/01 ${end_time}`);
            const diffMilliseconds = end - start;
            const diffHours = diffMilliseconds / (1000 * 60 * 60);
            return diffHours > 0;
        },
        getVacationsMonth(year, month) {
            const code = this.task['employee_code'];
            console.log(code)
            const vm = this;
            vm.vacations = [];
            ajax.fetchGet(common.formatStr(config.api.employee.employee_vacations_month,code, year, month)).then(data => {
                vm.vacations = data;
            }).catch(errors => {
                helper.show_errors(errors);
            });
        },
    }
}
</script>
<style>
.setting-container th,
.duty-container th,
.total-day-container th {
    background-color: #f2f2f2;
}

.attendance-view-fields .duty-container th {
    font-size: 0.8125rem;
}

.attendance-view-fields .mt20 {
    margin-top: 20px;
}

.attendance-view-fields td .form-group {
    margin-bottom: 0;
}

.duty-container .table th,
.duty-container .table td {
    padding: 0.5rem;
    vertical-align: middle;
    border: 1px solid #dee2e6;
    text-align: center;
}

.card .total-day-container .table th {
    padding-left: 0;
    padding-right: 1rem;
    vertical-align: middle;
}

.card .total-day-container .table td {
    vertical-align: middle;
}

.ml20 {
    margin-left: 20px;
}
.mg-t-10{
    margin-top: 10px;
}
.ft-14{
    font-size: 14px;
}
</style>
