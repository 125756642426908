<template>
  <validation-provider :rules="rules" :name="label" v-bind="$attrs" v-slot="{errors, valid, invalid, validated}">
    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}
          <span v-if="required || rules.required" style="color: red;">（*）</span>
        </label>
      </slot>
      <b-form-select
        :value="value"
        @input="updateValue"
        :name="label"
        :disabled="readonly"
        :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated}, inputClasses]"
      >
        <option :value="null" :selected="value===null">======</option>
        <template v-for="(item, key) in getOptions">
          <option
            :key="key"
            :value="item.value"
            :selected="item.value===value"
          >
            {{ item.text }}
          </option>
          <template v-for="(sub1, key1) in getChildren(item, 1)">
            <option
              :key="`sub_${key}_${key1}`"
              :value="sub1.value"
              :selected="sub1.value===value"
            >
              {{ sub1.text }}
            </option>
            <template v-for="(sub2, key2) in getChildren(sub1, 2)">
              <option
                :key="`sub_${key}_${key1}_${key2}`"
                :value="sub2.value"
                :selected="sub2.value===value"
              >
                {{ sub2.text }}
              </option>
              <template v-for="(sub3, key3) in getChildren(sub2, 3)">
                <option
                  :key="`sub_${key}_${key1}_${key2}_${key3}`"
                  :value="sub3.value"
                  :selected="sub3.value===value"
                >
                  {{ sub3.text }}
                </option>
              </template>
            </template>
          </template>
        </template>
      </b-form-select>
      <div :class="helpClasses" v-if="helpText">
        {{ helpText }}
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{successMessage}}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-select",
  props: {
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    helpClasses: {
      type: String,
      description: "Help text css classes",
      default: "form-control-help"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ''
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    value: {
      type: [String, Number],
      description: "Input value",
      default: null,
    },
    readonly: {
      type: Boolean,
      description: "Whether input is readonly",
      default: false
    },
    required: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    helpText: { type: String },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    getChildren(opt, deep) {
      const children = this.options.filter(o => o.parent === opt.value && o.parent !== null)
      children.map(i => {
        i.text = "　".repeat(deep) + i.text.trim();
        return i;
      })
      return children;
    },
  },
  computed: {
    getOptions: function() {
      return this.options.filter(o => !o.parent)
    },
  },
  mounted() {
    // if (this.options && this.options.length > 0) {
    //   if (this.value) {
    //     this.$emit("input", this.value);
    //   } else {
    //     this.$emit("input", this.options[0].value);
    //   }
    // }
  },
}
</script>