<template>
  <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{errors, valid, invalid, validated}">
    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}{{rules.required?"（*）" : ""}}
        </label>
      </slot>
      <b-form-datepicker
        :value="value"
        class="mb-2"
        today-button
        reset-button
        close-button
        locale="jp"
        @input="updateValue"
        :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated}, inputClasses]"
      >
      </b-form-datepicker>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{successMessage}}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-datepicker",
  props: {
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    value: {
      type: String,
      description: "Input value"
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
  },
  data() {
    return {
      focused: false
    };
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  }
}
</script>