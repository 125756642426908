import { Toast } from "../../components";
import { constant } from "../../utils";
import { api } from "../common";

export function get_schema_from_fields(fields) {
  return fields.map(item => ({
    "id": item.id,
    "name": item.name,
    "type": item.choices ? 'choice' : item.data_type,
    "label": item.label,
    "required": item.is_required,
    "read_only": item.is_readonly,
    "choices": item.choices,
    "default": item.value,
    "max_length": item.max_length,
    "help_text": item.help_text,
    "verbose_name": 'attachment_name',
    "handle_download": item.data_type === 'file' ? api.viewAttachment : null,
  }));
}

export function get_layout_from_fields(fields) {
  const order_list = Array.from(new Set(fields.map(item => item.order)));
  const fields_layout = [];
  let tmp = null;
  for (const order of order_list) {
    tmp = fields.filter(i => i.order === order).map(i => i.name);
    fields_layout.push(tmp.length === 1 ? tmp[0] : tmp);
  }
  return fields_layout;
}

export function get_value_from_fields(fields) {
  const data = {};
  fields.map(item => {
    if (item.data_type === 'file') {
      data[item.name] = item.attachment || null;
      data.attachment_name = item.attachment_name;
    } else if (item.data_type === 'boolean') {
      data[item.name] = item.value === 1 || item.value === true || item.value === "1"
    } else {
      data[item.name] = item.value;
    }
    return true;
  });
  return data;
}

export function show_errors(errors) {
  if (errors.detail) {
    Toast.error(errors.detail);
  } else {
    Toast.error(constant.ERROR.UNEXPECTED);
  }
}
