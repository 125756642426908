export default {
  SUCCESS: {
    SAVED: '保存しました。',
    DELETED: '削除しました。',
    UNDO: '差戻し成功しました。',
    SUBMITTED: '承認しました。',
    UPLOADED: 'ファイルアップロードしました。',
    RESENT: '再送信しました、しばらくお待ちください。',
  },
  CONFIRM: {
    UPLOAD_FILE: 'ファイルをアップロードします、よろしいですか？',
    CREATE_TASK: '申請します、よろしいですか？',
    SUBMIT_TASK_NODE: '承認します、よろしいですか？',
    UNDO_TASK_NODE: '差し戻してもよろしいですか？',
    SAVE: '保存します、よろしいですか？',
    SAVE_WORKFLOW: 'ワークフローを保存します、よろしいですか？',
    REMOVE_NODE: 'このノードを消します、よろしいですか？',
    DELETE: '削除します、よろしいですか？',
    RESEND: '再送信します、よろしいですか？',
  },
  ERROR: {
    REQUIRE_FILES: 'ファイルを選択してください。',
    UNEXPECTED: '予期できないエラーが発生しました。',
    REQUIRED_FIELD: '%s は必須項目です。',
    NO_PREV_COMMUTING: '先月の通勤変更がありません。',
    NO_PAID_VACATION: '有休情報取得できません。',
  },
  SELECT_OPTION_NULL: "======",
  PAGE_TITLE: {
    'home': 'EB Work',
    'proxy-list': '承認代理一覧',
    'proxy-add': '承認代理追加',
    'proxy-edit': '承認代理変更',
    'contacts': '緊急連絡先一覧',
    'band-accounts': '銀行口座一覧',
    'payment-slip': '給与明細一覧',
    'withholding': '源泉票一覧',
    'vacations': '休暇履歴',
    'manual': '社内マニュアル',
    'task-workflows': '各種手続き',
    'task-create': 'タスク追加',
    'tasks-unresolved': '申請中タスク一覧',
    'task-unresolved-detail': '申請中タスク',
    'task-detail': 'タスク詳細',
    'tasks-approval': '承認待ちタスク一覧',
    'task-approval-detail': '承認待ちタスク',
    'tasks-approval-finished': '承認完了一覧',
    'tasks-approval-finished-detail': '承認完了タスク',
    'tasks-finished': '完了済タスク一覧',
    'task-finished-detail': '完了済タスク',
    'tasks-observer': '観察可能一覧',
    'tasks-observer-detail': '観察可能タスク',
    'workflows': 'ワークフロー一覧',
    'workflow-detail': 'ワークフロー',
    'expense-uploads': '給与・源泉票発行',
    'expense-upload-list': 'アップロード履歴',
    'expense-upload-detail': 'アップロード履歴',
    'expense-attendance': '勤怠出力',
    'company-calendar': "年間就業カレンダー",
    'company-organization-chart': 'EB全社組織図',
    'attendance-pdf': '勤怠PDF出力',
    'attendance-list': '勤怠PDF出力',
  },
}