var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.label || _vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',[_vm._t("label",[(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required || _vm.rules.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("（*）")]):_vm._e()]):_vm._e()]),_c('div',[_c('multiselect',{class:[{'is-valid': valid && validated && _vm.successMessage}, {'is-invalid': invalid && validated}, _vm.inputClasses],attrs:{"label":"text","track-by":"value","placeholder":"Type to search","open-direction":"bottom","options":_vm.items,"multiple":_vm.multiple,"searchable":true,"loading":_vm.isLoading,"internal-search":false,"clear-on-select":false,"close-on-select":!_vm.multiple,"options-limit":300,"limit":3,"limit-text":_vm.limitText,"max-height":600,"show-no-results":false},on:{"search-change":_vm.fetchData,"select":_vm.onSelect},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"custom__tag"},[_c('span',[_vm._v(_vm._s(option.name))]),_c('span',{staticClass:"custom__remove",on:{"click":function($event){return remove(option)}}},[_vm._v("❌")])])]}},{key:"clear",fn:function(props){return [(_vm.selected.length)?_c('div',{staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clearAll(props.search)}}}):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Oops! No elements found. Consider changing the search query.")])])],1),(_vm.help_text)?_c('div',{staticClass:"help_text"},[_vm._v(" "+_vm._s(_vm.help_text)+" ")]):_vm._e(),_vm._t("success",[(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }