<template>
  <div class="bg-default">
    <!-- Header -->
    <div class="header bg-gradient-success py-6">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white">ようこそ!</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card v-if="access_token" no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pt-9 pb-9"  >
              <div class="btn-wrapper text-center">
                <span class="btn-inner--icon"><img src="img/icons/google.svg"></span>
                Gmail ログイン中...
              </div>
            </b-card-header>
          </b-card>
          <b-card v-else no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a :href="login_url" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/google.svg"></span>
                  <span class="btn-inner--text">E-Business Mail</span>
                </a>
              </div>
            </b-card-header>
            <b-card-body class="px-lg-5 py-lg-5">
              <div v-if="error_message" class="alert alert-danger" role="alert">
                {{ error_message }}
              </div>
              <div v-else class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="メールアドレス"
                              :rules="{required: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Username"
                              v-model="model.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="パスワード"
                              :rules="{required: true, min: 4}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <!-- <b-row v-if="!access_token" class="mt-3">
            <b-col cols="12">
              <router-link to="#" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { ajax, config } from '../../utils';

export default {
  data() {
    return {
      login_url: config.api.account.login_with_google,
      access_token: this.$route.query ? this.$route.query.access_token : null,
      csrf_token: this.$route.query ? this.$route.query.csrf_token : null,
      error_message: null,
      model: {
        username: '',
        password: '',
      }
    };
  },
  computed: {
    ...mapState('account', ['status'])
  },
  created: function() {
    document.title = "ログイン";
    if (this.access_token) {
      ajax.fetchGet(this.login_url, {access_token: this.access_token}).then(data => {
        this.login_openid(data);
      })
    }
  },
  methods: {
    ...mapActions('account', ['login', 'login_openid']),
    onSubmit() {
      // this will be called only after form is valid. You can do api call here to login
      const { username, password } = this.model;
      this.error_message = null;
      if (username && password) {
        this.login({username, password}).catch(errors => {
          this.error_message = errors.detail;
        })
      }
    }
  }
};
</script>
