<template>
    <b-table-simple>
        <b-tbody>
            <b-tr v-if="task.project_id">
                <b-th class="text-right">所属案件</b-th>
                <b-td>{{ task.project_name }}</b-td>
            </b-tr>
            <b-tr v-if="task.org_id">
                <b-th class="text-right">所属組織</b-th>
                <b-td>{{ task.org_name }}</b-td>
            </b-tr>
            <b-tr>
                <b-th class="text-right">申請者</b-th>
                <b-td>{{ task.employee_name }}</b-td>
            </b-tr>
            <!-- 資材申請, 資材購買申請-->
            <template v-for="(field, key) in fields">
                <b-tr v-if="field.name !== 'software_id' && field.name !== 'expiration_date' && field.name !== 'asset_id'" :key="key">
                    <b-th class="text-right">{{ field.label }}</b-th>
                    <b-td v-if="field.name === 'assets_type' && assetsExpirationDate > 0">{{ get_field_text(field) }}（{{ assetsExpirationDate }}ヶ月）</b-td>
                    <b-td v-else>{{ get_field_text(field) }}</b-td>
                </b-tr>
            </template>
        </b-tbody>
    </b-table-simple>
</template>
<script>
    export default {
        props: {
            task: { type: Object },
            fields: { type: [Array, Object] },
        },
        data() {
            return {
                assetsExpirationDate: 0,
            };
        },
        methods: {
            get_field_text(field) {
                if (field.choices) {
                    const choice = field.choices.find(i => i.value === field.value);
                    return choice ? choice.text : field.value;
                } else {
                    return field.value;
                }
            },
        },
        watch: {
            taskFields: {
                handler: function(newVal) {
                    if (newVal.task.workflow === '13' || newVal.task.workflow === '14') {
                        Object.values(newVal.fields).forEach((item) => {
                            if (item.name === 'expiration_date') {
                                this.assetsExpirationDate = this.get_field_text(item);
                            }
                        });
                    }
                },
                immediate: true,
                deep: true,
            },
        },
        computed: {
            taskFields() {
                return { task: this.task, fields: this.fields };
            },
        }
    }
</script>