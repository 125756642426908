import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

const firebaseConfig = {
  apiKey: "AIzaSyCjHq5yeIOheQ_EiArA5-o45ohrPLLHlv0",
  authDomain: "sales-yang.firebaseapp.com",
  databaseURL: "https://sales-yang.firebaseio.com",
  projectId: "sales-yang",
  storageBucket: "sales-yang.appspot.com",
  messagingSenderId: "998844496606",
  appId: "1:998844496606:web:51df346160a31e43e6204f"
};

firebase.initializeApp(firebaseConfig);

let messaging = null;
// モバイルなどFirebaseサポートしないデバイスがあるので、判断は必要
try {
  messaging = firebase.messaging();
} catch (e){
  console.log(e);
}

export default messaging;