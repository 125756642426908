const state = {
  statusCode: 200,
  message: null,
};

const actions = {
  setStatusCode({ commit }, status) {
    commit('setStatusCodeSuccess', status);
  },
  setServerError({ commit }, message) {
    commit('setServerErrorSuccess', message);
  },
};

const mutations = {
  setStatusCodeSuccess(state, status) {
    state.statusCode = status;
  },
  setServerErrorSuccess(state, message) {
    state.statusCode = 500;
    state.message = message;
  },
};

const getters = {
};

export const server = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};