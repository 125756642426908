<template>
    <v-stage :config="configKonva">
        <v-layer>
            <!-- 開始と終了 -->
            <v-circle :config="startCircle"></v-circle>
            <v-text :config="isVertical ? startCircleTextVertical : startCircleText"/>
            <v-circle :config="endCircle"></v-circle>
            <v-text :config="endCircleText"/>

            <!-- <v-line :config="{x: 115, y: 100, points: [0, 0, 370, 0], stroke: 'black'}" /> -->
            <!-- 各ノードを描画 -->
            <template v-for="(node, idx) in approverNodes">
                <template v-if="Array.isArray(node)">
                    <!-- 複数の人同時に承認できる場合 -->
                    <template v-for="(sub, idx2) in node">
                        <v-rect :key="`sub-${idx}-${idx2}`" :config="{
                          x: get_node_start_x(idx, idx2),
                          y: get_cascade_node_start_y(idx, idx2),
                          width: cfg.node_width,
                          height: cfg.node_height,
                          fill: 'white',
                          stroke: get_status_color(sub.status),
                        }"/>
                        <v-text
                            :key="`node-name-${idx}-${idx2}`"
                            :config="{
                            x: get_node_name_start_x(idx, idx2),
                            y: get_cascade_node_name_start_y(idx, idx2),
                            text: sub.node_name,
                            fill: 'gray',
                            fintSize: 12,
                          }"
                        />
                    </template>
                    <!-- ノードに紐づくテキストを描画 -->
                    <v-text v-for="(sub, idx2) in node" :key="`text-${idx}-${idx2}`" :config="{
                        x: get_node_start_x(idx, idx2) + get_node_text_start_x(sub),
                        y: get_cascade_node_start_y(idx, idx2) + 16,
                        text: get_node_inner_text(sub),
                        fintSize: 12,
                      }"/>
                    <template v-for="(sub, idx2) in node">
                        <v-line :key="`line-${idx}-${idx2}`" :config="{
                          ...get_line_start_pos(idx),
                          points: isVertical ? [0, 0, cfg.base_width * idx2, get_line_end_y(idx)] : [0, 0, get_line_end_x(idx, idx2), cfg.step_height * idx2],
                          stroke: get_status_color(sub.status),
                          dash: get_line_style(sub.status),
                        }"/>
                    </template>
                </template>
                <template v-else>
                    <v-rect :key="`node-${idx}`" :config="{
                        x: get_node_start_x(idx),
                        y: get_node_start_y(idx),
                        width: cfg.node_width,
                        height: cfg.node_height,
                        fill: 'white',
                        stroke: get_status_color(node.status),
                      }"/>
                    <v-text
                        :key="`node-name-${idx}`"
                        :config="{
                          x: get_node_name_start_x(idx),
                          y: get_node_name_start_y(idx),
                          text: node.node_name,
                          fill: 'gray',
                          fintSize: 12,
                        }"
                    />
                    <!-- ノードに紐づくテキストを描画 -->
                    <v-text :key="`text-${idx}`" :config="{
                        x: get_node_start_x(idx) + get_node_text_start_x(node),
                        y: get_node_start_y(idx) + 16,
                        text: get_node_inner_text(node),
                        fintSize: 12,
                      }"/>
                </template>
            </template>
            <!-- ノードの間に線を引く -->
            <template v-for="(node, idx) in approverNodes">
                <v-line :key="'line'+idx" v-if="!Array.isArray(node)" :config="{
                  ...get_line_start_pos(idx),
                  points: isVertical ? [0, 0, 0, get_line_end_y(idx)] : [0, 0, get_line_end_x(idx), 0],
                  stroke: get_status_color(node.status),
                  dash: get_line_style(node.status),
                }"/>
            </template>
            <!-- 終了との線を引く -->
            <v-line :config="{
                ...get_end_line_pos(),
                points: isVertical ? [0, 0, 0, 75] : [0, 0, 95, 0],
                stroke: get_status_color(task.status),
                dash: get_line_style(task.status),
              }"/>
            <!-- 観察者ノードを描画 -->
            <template v-for="(node, idx) in observerNodes">
                <template v-if="Array.isArray(node)">
                    <!-- 複数の人同時に承認できる場合 -->
                    <template v-for="(sub, idx2) in node">
                        <v-rect :key="`sub-${idx}-${idx2}`" :config="{
                          x: get_observer_node_start_x(idx, idx2),
                          y: get_observer_cascade_node_start_y(idx, idx2),
                          width: cfg.node_width,
                          height: cfg.node_height,
                          fill: 'white',
                          stroke: get_status_color(sub.status),
                        }"/>
                        <v-text
                            :key="`node-name-${idx}-${idx2}`"
                            :config="{
                            x: get_observer_node_name_start_x(idx, idx2),
                            y: get_observer_cascade_node_name_start_y(idx, idx2),
                            text: sub.node_name,
                            fill: 'gray',
                            fintSize: 12,
                          }"
                        />
                    </template>
                    <!-- ノードに紐づくテキストを描画 -->
                    <v-text v-for="(sub, idx2) in node" :key="`text-${idx}-${idx2}`" :config="{
                        x: get_observer_node_start_x(idx, idx2) + get_node_text_start_x(sub),
                        y: get_observer_cascade_node_start_y(idx, idx2) + 16,
                        text: get_node_inner_text(sub),
                        fintSize: 12,
                      }"/>

                </template>
                <template v-else>
                    <v-rect :key="`node-${idx}`" :config="{
                        x: get_observer_node_start_x(idx),
                        y: get_observer_node_start_y(idx),
                        width: cfg.node_width,
                        height: cfg.node_height,
                        fill: 'white',
                        stroke: get_status_color(node.status),
                      }"/>
                    <v-text
                        :key="`node-name-${idx}`"
                        :config="{
                          x: get_observer_node_name_start_x(idx),
                          y: get_observer_node_name_start_y(idx),
                          text: node.node_name,
                          fill: 'gray',
                          fintSize: 12,
                        }"
                    />
                    <!-- ノードに紐づくテキストを描画 -->
                    <v-text :key="`text-${idx}`" :config="{
                        x: get_observer_node_start_x(idx) + get_node_text_start_x(node),
                        y: get_observer_node_start_y(idx) + 16,
                        text: get_node_inner_text(node),
                        fintSize: 12,
                      }"/>
                </template>
            </template>
        </v-layer>
    </v-stage>
</template>

<script>
import breakpoints from "@/utils/breakpoints";

const canvas_config = {
    base_width: 150,
    start_pos: 100,  // 水平方向の開始位置
    base_line_pos: 100,  // 垂直方向の開始位置
    node_width: 80,
    node_height: 40,
    finished_fill: "lightGray",
    finished_stroke: "gray",
    circle_radius: 15,
    step_height: 80,
}
export default {
    name: 'flow-chart',
    props: {
        task: {type: Object, default: () => ({})},
        nodes: {type: Array, default: () => []},
    },
    data() {
        return {
            cfg: canvas_config,
            startCircle: {
                x: canvas_config.start_pos,
                y: canvas_config.base_line_pos,
                radius: canvas_config.circle_radius,
                fill: canvas_config.finished_fill,
                stroke: canvas_config.finished_stroke,
                strokeWidth: 1
            },
            startCircleText: {
                x: canvas_config.start_pos - 11,
                y: canvas_config.base_line_pos + 20,
                text: "開始",
                fintSize: 12,
            },
            startCircleTextVertical: {
                x: canvas_config.start_pos + canvas_config.circle_radius + 5,
                y: canvas_config.base_line_pos - 5,
                text: "開始",
                fintSize: 12,
            },
            maxApproverNodeY: 0,
            maxApproverNodeX: 0,
        };
    },
    computed: {
        breakpoints: () => breakpoints.screen,
        isVertical: function () {
            return this.breakpoints.lt.md;
        },
        configKonva: function () {
            return {
                width: this.get_canvas_width(),
                height: this.get_canvas_height(),
            }
        },
        endCircle: function () {
            if (this.isVertical) {
                return {
                    x: canvas_config.start_pos,
                    y: (this.approverNodes.length + 1) * (this.cfg.node_height + this.cfg.step_height) + this.cfg.base_line_pos - 30,
                    radius: canvas_config.circle_radius,
                    fill: this.task.status === '90' ? canvas_config.finished_fill : "white",
                    stroke: this.get_status_color(this.task.status),
                    strokeWidth: 1
                }
            } else {
                return {
                    x: (this.approverNodes.length + 1) * this.cfg.base_width + this.cfg.start_pos,
                    y: this.cfg.base_line_pos,
                    radius: canvas_config.circle_radius,
                    fill: this.task.status === '90' ? canvas_config.finished_fill : "white",
                    stroke: this.get_status_color(this.task.status),
                    strokeWidth: 1
                }
            }
        },
        endCircleText: function () {
            if (this.isVertical) {
                return {
                    x: canvas_config.start_pos + canvas_config.circle_radius + 5,
                    y: (this.approverNodes.length + 1) * (this.cfg.node_height + this.cfg.step_height) + this.cfg.base_line_pos - 35,
                    text: "終了",
                    fintSize: 12,
                };
            } else {
                return {
                    x: (this.approverNodes.length + 1) * this.cfg.base_width + this.cfg.start_pos - 11,
                    y: this.cfg.base_line_pos + 20,
                    text: "終了",
                    fintSize: 12,
                };
            }
        },
        approverNodes: function () {
            return this.nodes.filter(n => {
                if (Array.isArray(n)) {
                    return n.some(sub => sub.node !== '95');
                } else {
                    return n.node !== '95';
                }
            });
        },
        observerNodes: function () {
            return this.nodes.filter(n => {
                if (Array.isArray(n)) {
                    return n.some(sub => sub.node === '95');
                } else {
                    return n.node === '95';
                }
            });
        },
    },
    methods: {
        get_canvas_width: function () {
            if (this.isVertical) {
                return 485;
            } else {
                return (this.approverNodes.length + 1) * this.cfg.base_width + this.cfg.start_pos * 2;
            }
        },
        get_canvas_height: function () {
            if (this.isVertical) {
                if (this.approverNodes.length > 0) {
                    return 100 + (this.approverNodes.length + 2) * (canvas_config.step_height + canvas_config.node_height);
                } else {
                    return 250;
                }
            } else {
                const approver_max_size = Math.max(...this.approverNodes.map(n => Array.isArray(n) ? n.length : 1));
                const observer_max_size = Math.max(...this.observerNodes.map(n => Array.isArray(n) ? n.length : 1));
                const max_size = approver_max_size + observer_max_size;
                if (max_size > 1) {
                    return 120 + max_size * (canvas_config.step_height - 1);
                } else {
                    return 200;
                }
            }
        },
        get_status_color: function (status) {
            if (status === '01') {
                // 未承認
                return 'blue';
            } else if (status === '10') {
                // 承認中
                return 'red';
            } else if (status >= '90') {
                // 承認済・他人承認済
                return 'gray';
            } else if (status === '02') {
                // 差戻し
                return 'yellow';
            } else {
                return 'blue';
            }
        },
        get_node_start_x: function (index, index2) {
            if (this.isVertical) {
                const startX = canvas_config.start_pos - canvas_config.node_width / 2 + (index2 ? canvas_config.base_width : 0);
                if (startX > this.maxApproverNodeX) {
                    this.maxApproverNodeX = startX
                }
                return startX
            } else {
                return canvas_config.start_pos + canvas_config.base_width * (index + 1) - canvas_config.node_width / 2;
            }
        },
        get_observer_node_start_x: function (index, index2) {
            if (this.isVertical) {
                const startX = canvas_config.start_pos - canvas_config.node_width / 2 + (index2 ? canvas_config.base_width : 0);
                return startX + this.maxApproverNodeX + canvas_config.node_width
            } else {
                return this.get_node_start_x(index, index2)
            }
        },
        // ノードの名称開始位置
        get_node_name_start_x: function (index, index2) {
            return this.get_node_start_x(index, index2);
        },
        get_observer_node_name_start_x: function (index, index2) {
            return this.get_observer_node_start_x(index, index2)
        },
        get_node_text_start_x: function (node) {
            if (node.is_proxy) {
                return 3;
            } else {
                return 18;
            }
        },
        get_node_start_y: function (index) {
            if (this.isVertical) {
                // 開始位置 + 開始円の高さ + (ノードの高さ + ノード間隔) * ノード数
                return canvas_config.start_pos + canvas_config.step_height + (canvas_config.node_height + canvas_config.step_height) * index
            } else {
                const start_y = canvas_config.base_line_pos - canvas_config.node_height / 2;
                if (start_y > this.maxApproverNodeY) {
                    this.maxApproverNodeY = start_y
                }
                return start_y
            }
        },
        // ノードの名称開始位置
        get_node_name_start_y: function (index) {
            return this.get_node_start_y(index) + canvas_config.node_height + 5;
        },
        get_cascade_node_start_y: function (index, index2) {
            if (this.isVertical) {
                return this.get_node_start_y(index);
            } else {
                const start_y = canvas_config.step_height * index2 + canvas_config.base_line_pos - canvas_config.node_height / 2;
                if (start_y > this.maxApproverNodeY) {
                    this.maxApproverNodeY = start_y
                }
                return start_y
            }
        },
        get_observer_node_start_y: function (index) {
            if (this.isVertical) {
                return this.get_node_start_y(index);
            } else {
                const start_y = canvas_config.base_line_pos - canvas_config.node_height / 2;
                return start_y + this.maxApproverNodeY
            }
        },
        get_observer_cascade_node_start_y: function (index, index2) {
            if (this.isVertical) {
                return this.get_node_start_y(index);
            } else {
                const start_y = canvas_config.step_height * index2 + canvas_config.base_line_pos - canvas_config.node_height / 2;
                return start_y + this.maxApproverNodeY
            }
        },
        // ノードの名称開始位置
        get_cascade_node_name_start_y: function (index, index2) {
            return this.get_cascade_node_start_y(index, index2) + canvas_config.node_height + 5;
        },
        get_observer_node_name_start_y: function (index) {
            return this.get_observer_node_start_y(index) + canvas_config.node_height + 5;
        },
        get_observer_cascade_node_name_start_y: function (index, index2) {
            return this.get_observer_node_start_y(index, index2) + canvas_config.node_height + 5;
        },
        get_line_start_pos: function (index) {
            if (index === 0) {
                // 開始円からのライン
                if (this.isVertical) {
                    return {
                        x: canvas_config.start_pos,
                        y: canvas_config.base_line_pos + canvas_config.circle_radius
                    };
                } else {
                    return {
                        x: canvas_config.start_pos + canvas_config.circle_radius,
                        y: canvas_config.base_line_pos
                    };
                }
            } else {
                if (this.isVertical) {
                    return {
                        x: canvas_config.start_pos,
                        y: this.get_node_start_y(index) - canvas_config.step_height,  // ノードの開始位置 - ノード間隔
                    }
                } else {
                    return {
                        x: canvas_config.start_pos + canvas_config.base_width * index + canvas_config.node_width / 2,
                        y: canvas_config.base_line_pos
                    }
                }
            }
        },
        get_line_end_x: function (index) {
            return this.get_node_start_x(index) - this.get_line_start_pos(index).x;
        },
        get_line_end_y: function (index) {
            if (index === 0) {
                // ノードの開始位置 - 垂直方向の開始位置 - 開始円の半径
                return this.get_node_start_y(index) - canvas_config.base_line_pos - canvas_config.circle_radius;
            } else {
                return canvas_config.step_height;
            }
        },
        get_end_line_pos: function () {
            if (this.isVertical) {
                return {
                    x: canvas_config.start_pos,
                    y: this.get_node_start_y(this.approverNodes.length - 1) + canvas_config.node_height,
                }
            } else {
                return {
                    x: this.get_node_start_x(this.approverNodes.length - 1) + canvas_config.node_width,
                    y: canvas_config.base_line_pos,
                }
            }
        },
        get_line_style: function (status) {
            if (status >= '10') {
                return null;
            } else {
                return [5, 5];
            }
        },
        get_node_inner_text: function (node) {
            if (node.is_proxy) {
                return `${node.approver_name}(代理)`;
            } else {
                return node.approver_name;
            }
        }
    },
}
</script>