<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
      <div v-show="sidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <b-avatar :src="avatar"></b-avatar>
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <b-icon icon="x" @click="showSidebar(false)"></b-icon>
            </div>
          </div>
        </div>
        <div class="navbar-avatar">
          <div class="p-4 text-center">
            <b-avatar size="9rem" :src="avatar"></b-avatar>
          </div>
          <div class="text-center mb-3" style="font-size: 13px;">
            <div class="h5 font-weight-300">{{ username }}</div>
          </div>
        </div>
        <b-list-group flush>
          <b-list-group-item :to="{name: 'home'}" @click="showSidebar(false)">
            <i class="fas fa-home"></i>&nbsp;ホーム
          </b-list-group-item>
          <b-list-group-item :to="{name: 'task-workflows'}" @click="showSidebar(false)">
            <b-icon icon="journal-plus"></b-icon>&nbsp;各種手続き
          </b-list-group-item>
          <b-list-group-item :to="{name: 'manual'}" @click="showSidebar(false)">
            <i class="fas fa-file-pdf"></i>&nbsp;社内マニュアル
          </b-list-group-item>
          <b-list-group-item v-b-toggle.collapse-task>
            <i class="fas fa-tasks"></i>&nbsp;タスク
            <b-badge v-if="cnt_task_all" variant="danger" class="float-right mr-4" pill>{{ cnt_task_all }}</b-badge>
          </b-list-group-item>
          <b-collapse id="collapse-task">
            <b-list-group flush>
              <b-list-group-item :to="{name: 'tasks-unresolved'}" @click="showSidebar(false)">
                申請中一覧
                <b-badge v-if="cnt_unresolved_tasks" variant="danger" class="float-right" pill>{{ cnt_unresolved_tasks }}</b-badge>
              </b-list-group-item>
              <b-list-group-item :to="{name: 'tasks-finished'}" @click="showSidebar(false)">
                完了済一覧
              </b-list-group-item>
              <b-list-group-item :to="{name: 'tasks-approval'}" @click="showSidebar(false)">
                承認待ち一覧
                <b-badge v-if="cnt_approval_tasks" variant="danger" class="float-right" pill>{{ cnt_approval_tasks }}</b-badge>
              </b-list-group-item>
              <b-list-group-item :to="{name: 'tasks-approval-finished'}" @click="showSidebar(false)">
                承認完了一覧
              </b-list-group-item>
                <b-list-group-item :to="{name: 'tasks-observer'}" @click="showSidebar(false)">
                    観察可能一覧
                </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <b-list-group-item v-if="hasPerm('workflow.view_workflow')" v-b-toggle.collapse-workflow>
            <b-icon icon="diagram3"></b-icon>&nbsp;ワークフロー
          </b-list-group-item>
          <b-collapse id="collapse-workflow">
            <b-list-group flush>
              <b-list-group-item v-if="hasPerm('workflow.view_workflow')" :to="{name: 'workflows'}" @click="showSidebar(false)">
                ワークフロー一覧
              </b-list-group-item>
              <b-list-group-item v-if="hasPerm('workflow.view_node')" :to="{name: 'workflow-nodes'}" @click="showSidebar(false)">
                ノード一覧
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <b-list-group-item v-if="hasPerm(['expense.add_uploadhistory', 'expense.view_uploadhistory', 'expense.view_attendance'])" v-b-toggle.collapse-expense>
            <i class="fas fa-dollar-sign"></i>&nbsp;経費管理
          </b-list-group-item>
          <b-collapse id="collapse-expense">
            <b-list-group flush>
              <b-list-group-item v-if="hasPerm('expense.add_uploadhistory')" :to="{ name: 'expense-uploads' }" @click="showSidebar(false)">
                給与・源泉票発行
              </b-list-group-item>
              <b-list-group-item v-if="hasPerm('expense.view_uploadhistory')" :to="{ name: 'expense-upload-list' }" @click="showSidebar(false)">
                アップロード履歴
              </b-list-group-item>
              <b-list-group-item v-if="hasPerm('expense.view_attendance')" :to="{ name: 'expense-attendance' }" @click="showSidebar(false)">
                勤怠出力
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <b-list-group-item :to="{ name: 'company-calendar' }" @click="showSidebar(false)">
            <b-icon icon="calendar2-month"></b-icon>&nbsp;年間就業カレンダー
          </b-list-group-item>
          <b-list-group-item :to="{name: 'company-organization-chart'}" @click="showSidebar(false)">
            <b-icon icon="bar-chart-steps"></b-icon>&nbsp;EB全社組織図
          </b-list-group-item>
          <b-list-group-item v-if="hasPerm('employee.view_attendance')" :to="{name: 'attendance-pdf'}" @click="showSidebar(false)">
            <i class="far fa-calendar-alt"></i>&nbsp;勤怠PDF出力
          </b-list-group-item>
          <b-list-group-item v-if="isStaff" :href="`${serverApi}/admin`" target="_blank" @click="showSidebar(false)">
            <b-icon icon="gear"></b-icon>&nbsp;設定
          </b-list-group-item>
        </b-list-group>
      </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { hostApi } from '../../utils/config';

export default {
  computed: {
    ...mapGetters('account', ['hasPerm', 'isStaff', 'sidebar']),
    ...mapState('account', ['user', 'statistics']),
    cnt_unresolved_tasks: function() {
      if (this.statistics) {
        return this.statistics.unresolved_tasks;
      } else {
        return 0
      }
    },
    cnt_approval_tasks: function() {
      if (this.statistics) {
        return this.statistics.approval_tasks;
      } else {
        return 0
      }
    },
    cnt_task_all: function() {
      return this.cnt_unresolved_tasks + this.cnt_approval_tasks
    },
    serverApi: function() {
      return hostApi;
    },
    avatar() {
      return this.user && this.user.me ? this.user.me.avatar : null;
    },
    username() {
      return this.user && this.user.me ? this.user.me.username : null;
    },
  },
  methods: {
    ...mapActions('account', ['showSidebar']),
  },
}
</script>
<style scoped>
nav {
  padding: 0 !important;
  font-size: 14px !important;
}
nav .list-group {
  width: 100%;
}
.list-group-item.collapsed::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  margin-left: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}
.list-group-item.not-collapsed::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  margin-left: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
#collapse-task div.list-group .list-group-item,
#collapse-workflow div.list-group .list-group-item ,
#collapse-expense div.list-group .list-group-item {
  padding-left: 33px;
}
</style>
