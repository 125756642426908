<template>
  <div class="not-found">
    <div>
      <span class="num">5</span>
      <span class="num"><i class="fas fa-sad-tear"></i></span>
      <span class="num"><i class="fas fa-sad-tear"></i></span>
    </div>
    <div class="desc">
      {{ message }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('server', ['statusCode', 'message']),
  }
}
</script>