<template>
  <validation-provider :rules="rules" :name="label || name" v-bind="$attrs" v-slot="{errors, valid, invalid, validated}">
    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}
          <span v-if="required || rules.required" style="color: red;">（*）</span>
        </label>
      </slot>
      <div>
        <multiselect
          v-model="selected"
          label="text"
          track-by="value"
          placeholder="Type to search"
          open-direction="bottom"
          :options="items"
          :multiple="multiple"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="!multiple"
          :options-limit="300"
          :limit="3"
          :limit-text="limitText"
          :max-height="600"
          :show-no-results="false"
          @search-change="fetchData"
          @select="onSelect"
          :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated}, inputClasses]"
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="custom__tag">
              <span>{{ option.name }}</span>
              <span class="custom__remove" @click="remove(option)">❌</span>
            </span>
          </template>
          <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="selected.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
          </template>
          <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
        </multiselect>
      </div>
      <div v-if="help_text" class="help_text">
        {{ help_text }}
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{successMessage}}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
export default {
  inheritAttrs: false,
  name: "base-autocomplete",
  props: {
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    help_text: { type: String, default: null },
    multiple: { type: Boolean, default: false },
    data_source: { type: Function, default: null },
    column: { type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
    value: {
      type: [String, Number, Boolean],
      description: "Input value"
    },
    field_for_init: {
      type: [String, Number, Boolean],
      description: "初期化するために使う、検索キーワードとvalueが不一致の場合に必要"
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ''
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    inputGroupClasses: {
      type: String,
      description: "Input group css classes"
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      isLoading: false,
    }
  },
  mounted() {
    if (this.value && this.data_source) {
      const vm = this;
      this.data_source(vm.query_for_init, vm.data).then(data => {
        vm.items = data;
        if (vm.items.length > 0) {
          vm.selected = vm.items[0]
        }
      });
    }
  },
  computed: {
    query_for_init: function() {
      if (this.field_for_init) {
        return this.data[this.field_for_init];
      } else {
        return this.value;
      }
    }
  },
  methods: {
    limitText (count) {
      return `and ${count} other countries`
    },
    fetchData(query) {
      const vm = this;
      if (this.data_source) {
        vm.isLoading = true;
        this.data_source(query, vm.data).then(data => {
          vm.items = data;
        }).finally(() => {
          vm.isLoading = false;
        });
      } else {
        vm.items = [];
      }
    },
    onSelect(option) {
      if (option) {
        if (this.multiple) {
          this.$emit('input', option.map(i => i.value));
        } else {
          this.$emit('input', option.value);
        }
      }
    },
    clearAll () {
      this.selected = []
    }
  }
}
</script>