import { Toast } from '../../components';
import { ajax, common, config } from "../../utils";

/**
 * 画像をダイアログで表示する
 * @param {String} name 画像名称
 * @param {base64} base64 画像のbase64型データ
 * @param {Object} context vueのインスタンス
 */
export function showImage(name, base64, context) {
  const h = context.$createElement;
  const messageVNode = h('b-img', {
    props: {
      src: `data:image/png;base64,${base64}`,
      center: true,
      fluid: true,
    }
  });
  context.$bvModal.msgBoxOk([messageVNode], {
    title: name,
    buttonSize: 'sm',
    centered: true,
    size: 'lg',
    hideHeaderClose: false,
    hideFooter: true
  });
}

/**
 * UUIDによってファイルを表示する
 * @param {String} uuid ファイルの唯一ID
 * @returns 
 */
export function viewAttachment(uuid, context) {
  return ajax.fetchGet(common.formatStr(config.api.master.attachment_download, uuid)).then(data => {
    if (common.isImage(data.name)) {
      showImage(data.name, data.blob, context);
    } else if (common.isPdf(data.name)) {
      const pdfFile = common.toBlob(data.blob, 'application/pdf');
      const fileURL = URL.createObjectURL(pdfFile);
      window.open(fileURL);
    } else {
      const blob = common.toBlob(data.blob);
      common.downloadBlob(blob, data.name);
    }
  }).catch(errors => {
    Toast.error(errors.detail);
    return Promise.reject(errors);
  });
}

export function getBankChoices(query) {
  if (query) {
    if (query.match(/[0-9]+/) && query.length < 4) {
      return Promise.resolve([]);
    }
    return ajax.fetchGet(`${config.api.master.bank_choice}?query=${query}`).catch(errors => {
      Toast.error(errors.detail);
      return Promise.reject(errors);
    });
  }
  return Promise.resolve([]);
}

export function getBranchChoices(query, data) {
  if (query) {
    if (typeof(query) === 'string' && query.match(/[0-9]+/) && query.length < 3) {
      return Promise.resolve([]);
    }
    const bank_code = data.bank;
    if (bank_code) {
      const api_url = common.formatStr(config.api.master.branch_choice, bank_code);
      return ajax.fetchGet(`${api_url}?query=${query}`).catch(errors => {
        Toast.error(errors.detail);
        return Promise.reject(errors);
      });
    }
  }
  return Promise.resolve([]);
}

export default {
  showImage,
  viewAttachment,
  getBankChoices,
  getBranchChoices,
};