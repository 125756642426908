import VueRouter from "vue-router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import GlobalComponents from './GlobalComponents';
import GlobalDirectives from './GlobalDirectives';
import VueKonva from 'vue-konva'
import VueCroppie from 'vue-croppie';
import VueViewer from 'v-viewer'

import '@/assets/scss/argon.scss';
import 'croppie/croppie.css' // import the croppie css manually
import 'viewerjs/dist/viewer.css'

export default {
  install(Vue) {
    Vue.use(VueRouter);
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    Vue.use(VueKonva);
    Vue.use(VueCroppie);
    Vue.use(VueViewer);
  }
};
