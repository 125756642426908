export default {
    gender: [
        {"value": '1', "text": '男'},
        {"value": '2', "text": '女'}
    ],
    marriage: [
        {"value": "0", "text": "未婚",},
        {"value": "1", "text": "既婚",}
    ],
    country: [
        {"value": "392", "text": "日本"},
        {"value": "156", "text": "中華人民共和国"},
        {"value": "004", "text": "アフガニスタン"},
        {"value": "008", "text": "アルバニア"},
        {"value": "010", "text": "南極"},
        {"value": "012", "text": "アルジェリア"},
        {"value": "016", "text": "アメリカ領サモア"},
        {"value": "020", "text": "アンドラ"},
        {"value": "024", "text": "アンゴラ"},
        {"value": "028", "text": "アンティグア・バーブーダ"},
        {"value": "031", "text": "アゼルバイジャン"},
        {"value": "032", "text": "アルゼンチン"},
        {"value": "036", "text": "オーストラリア"},
        {"value": "040", "text": "オーストリア"},
        {"value": "044", "text": "バハマ"},
        {"value": "048", "text": "バーレーン"},
        {"value": "050", "text": "バングラデシュ"},
        {"value": "051", "text": "アルメニア"},
        {"value": "052", "text": "バルバドス"},
        {"value": "056", "text": "ベルギー"},
        {"value": "060", "text": "バミューダ"},
        {"value": "064", "text": "ブータン"},
        {"value": "068", "text": "ボリビア多民族国"},
        {"value": "070", "text": "ボスニア・ヘルツェゴビナ"},
        {"value": "072", "text": "ボツワナ"},
        {"value": "074", "text": "ブーベ島"},
        {"value": "076", "text": "ブラジル"},
        {"value": "084", "text": "ベリーズ"},
        {"value": "086", "text": "イギリス領インド洋地域"},
        {"value": "090", "text": "ソロモン諸島"},
        {"value": "092", "text": "イギリス領ヴァージン諸島"},
        {"value": "096", "text": "ブルネイ・ダルサラーム"},
        {"value": "100", "text": "ブルガリア"},
        {"value": "104", "text": "ミャンマー"},
        {"value": "108", "text": "ブルンジ"},
        {"value": "112", "text": "ベラルーシ"},
        {"value": "116", "text": "カンボジア"},
        {"value": "120", "text": "カメルーン"},
        {"value": "124", "text": "カナダ"},
        {"value": "132", "text": "カーボベルデ"},
        {"value": "136", "text": "ケイマン諸島"},
        {"value": "140", "text": "中央アフリカ共和国"},
        {"value": "144", "text": "スリランカ"},
        {"value": "148", "text": "チャド"},
        {"value": "152", "text": "チリ"},
        {"value": "158", "text": "台湾（中華民国）"},
        {"value": "162", "text": "クリスマス島"},
        {"value": "166", "text": "ココス（キーリング）諸島"},
        {"value": "170", "text": "コロンビア"},
        {"value": "174", "text": "コモロ"},
        {"value": "175", "text": "マヨット"},
        {"value": "178", "text": "コンゴ共和国"},
        {"value": "180", "text": "コンゴ民主共和国"},
        {"value": "184", "text": "クック諸島"},
        {"value": "188", "text": "コスタリカ"},
        {"value": "191", "text": "クロアチア"},
        {"value": "192", "text": "キューバ"},
        {"value": "196", "text": "キプロス"},
        {"value": "203", "text": "チェコ"},
        {"value": "204", "text": "ベナン"},
        {"value": "208", "text": "デンマーク"},
        {"value": "212", "text": "ドミニカ国"},
        {"value": "214", "text": "ドミニカ共和国"},
        {"value": "218", "text": "エクアドル"},
        {"value": "222", "text": "エルサルバドル"},
        {"value": "226", "text": "赤道ギニア"},
        {"value": "231", "text": "エチオピア"},
        {"value": "232", "text": "エリトリア"},
        {"value": "233", "text": "エストニア"},
        {"value": "234", "text": "フェロー諸島"},
        {"value": "238", "text": "フォークランド（マルビナス）諸島"},
        {"value": "239", "text": "サウスジョージア・サウスサンドウィッチ諸島"},
        {"value": "242", "text": "フィジー"},
        {"value": "246", "text": "フィンランド"},
        {"value": "248", "text": "オーランド諸島"},
        {"value": "250", "text": "フランス"},
        {"value": "254", "text": "フランス領ギアナ"},
        {"value": "258", "text": "フランス領ポリネシア"},
        {"value": "260", "text": "フランス領南方・南極地域"},
        {"value": "262", "text": "ジブチ"},
        {"value": "266", "text": "ガボン"},
        {"value": "268", "text": "ジョージア"},
        {"value": "270", "text": "ガンビア"},
        {"value": "275", "text": "パレスチナ"},
        {"value": "276", "text": "ドイツ"},
        {"value": "288", "text": "ガーナ"},
        {"value": "292", "text": "ジブラルタル"},
        {"value": "296", "text": "キリバス"},
        {"value": "300", "text": "ギリシャ"},
        {"value": "304", "text": "グリーンランド"},
        {"value": "308", "text": "グレナダ"},
        {"value": "312", "text": "グアドループ"},
        {"value": "316", "text": "グアム"},
        {"value": "320", "text": "グアテマラ"},
        {"value": "324", "text": "ギニア"},
        {"value": "328", "text": "ガイアナ"},
        {"value": "332", "text": "ハイチ"},
        {"value": "334", "text": "ハード島とマクドナルド諸島"},
        {"value": "336", "text": "バチカン市国"},
        {"value": "340", "text": "ホンジュラス"},
        {"value": "344", "text": "香港"},
        {"value": "348", "text": "ハンガリー"},
        {"value": "352", "text": "アイスランド"},
        {"value": "356", "text": "インド"},
        {"value": "360", "text": "インドネシア"},
        {"value": "364", "text": "イラン・イスラム共和国"},
        {"value": "368", "text": "イラク"},
        {"value": "372", "text": "アイルランド"},
        {"value": "376", "text": "イスラエル"},
        {"value": "380", "text": "イタリア"},
        {"value": "384", "text": "コートジボワール"},
        {"value": "388", "text": "ジャマイカ"},
        {"value": "398", "text": "カザフスタン"},
        {"value": "400", "text": "ヨルダン"},
        {"value": "404", "text": "ケニア"},
        {"value": "408", "text": "朝鮮民主主義人民共和国"},
        {"value": "410", "text": "大韓民国"},
        {"value": "414", "text": "クウェート"},
        {"value": "417", "text": "キルギス"},
        {"value": "418", "text": "ラオス人民民主共和国"},
        {"value": "422", "text": "レバノン"},
        {"value": "426", "text": "レソト"},
        {"value": "428", "text": "ラトビア"},
        {"value": "430", "text": "リベリア"},
        {"value": "434", "text": "リビア"},
        {"value": "438", "text": "リヒテンシュタイン"},
        {"value": "440", "text": "リトアニア"},
        {"value": "442", "text": "ルクセンブルク"},
        {"value": "446", "text": "マカオ"},
        {"value": "450", "text": "マダガスカル"},
        {"value": "454", "text": "マラウイ"},
        {"value": "458", "text": "マレーシア"},
        {"value": "462", "text": "モルディブ"},
        {"value": "466", "text": "マリ"},
        {"value": "470", "text": "マルタ"},
        {"value": "474", "text": "マルティニーク"},
        {"value": "478", "text": "モーリタニア"},
        {"value": "480", "text": "モーリシャス"},
        {"value": "484", "text": "メキシコ"},
        {"value": "492", "text": "モナコ"},
        {"value": "496", "text": "モンゴル"},
        {"value": "498", "text": "モルドバ共和国"},
        {"value": "499", "text": "モンテネグロ"},
        {"value": "500", "text": "モントセラト"},
        {"value": "504", "text": "モロッコ"},
        {"value": "508", "text": "モザンビーク"},
        {"value": "512", "text": "オマーン"},
        {"value": "516", "text": "ナミビア"},
        {"value": "520", "text": "ナウル"},
        {"value": "524", "text": "ネパール"},
        {"value": "528", "text": "オランダ"},
        {"value": "531", "text": "キュラソー"},
        {"value": "533", "text": "アルバ"},
        {"value": "534", "text": "シント・マールテン（オランダ領）"},
        {"value": "535", "text": "ボネール、シント・ユースタティウスおよびサバ"},
        {"value": "540", "text": "ニューカレドニア"},
        {"value": "548", "text": "バヌアツ"},
        {"value": "554", "text": "ニュージーランド"},
        {"value": "558", "text": "ニカラグア"},
        {"value": "562", "text": "ニジェール"},
        {"value": "566", "text": "ナイジェリア"},
        {"value": "570", "text": "ニウエ"},
        {"value": "574", "text": "ノーフォーク島"},
        {"value": "578", "text": "ノルウェー"},
        {"value": "580", "text": "北マリアナ諸島"},
        {"value": "581", "text": "合衆国領有小離島"},
        {"value": "583", "text": "ミクロネシア連邦"},
        {"value": "584", "text": "マーシャル諸島"},
        {"value": "585", "text": "パラオ"},
        {"value": "586", "text": "パキスタン"},
        {"value": "591", "text": "パナマ"},
        {"value": "598", "text": "パプアニューギニア"},
        {"value": "600", "text": "パラグアイ"},
        {"value": "604", "text": "ペルー"},
        {"value": "608", "text": "フィリピン"},
        {"value": "612", "text": "ピトケアン"},
        {"value": "616", "text": "ポーランド"},
        {"value": "620", "text": "ポルトガル"},
        {"value": "624", "text": "ギニアビサウ"},
        {"value": "626", "text": "東ティモール"},
        {"value": "630", "text": "プエルトリコ"},
        {"value": "634", "text": "カタール"},
        {"value": "638", "text": "レユニオン"},
        {"value": "642", "text": "ルーマニア"},
        {"value": "643", "text": "ロシア連邦"},
        {"value": "646", "text": "ルワンダ"},
        {"value": "652", "text": "サン・バルテルミー"},
        {"value": "654", "text": "セントヘレナ・アセンションおよびトリスタンダクーニャ"},
        {"value": "659", "text": "セントクリストファー・ネイビス"},
        {"value": "660", "text": "アンギラ"},
        {"value": "662", "text": "セントルシア"},
        {"value": "663", "text": "サン・マルタン（フランス領）"},
        {"value": "666", "text": "サンピエール島・ミクロン島"},
        {"value": "670", "text": "セントビンセントおよびグレナディーン諸島"},
        {"value": "674", "text": "サンマリノ"},
        {"value": "678", "text": "サントメ・プリンシペ"},
        {"value": "682", "text": "サウジアラビア"},
        {"value": "686", "text": "セネガル"},
        {"value": "688", "text": "セルビア"},
        {"value": "690", "text": "セーシェル"},
        {"value": "694", "text": "シエラレオネ"},
        {"value": "702", "text": "シンガポール"},
        {"value": "703", "text": "スロバキア"},
        {"value": "704", "text": "ベトナム"},
        {"value": "705", "text": "スロベニア"},
        {"value": "706", "text": "ソマリア"},
        {"value": "710", "text": "南アフリカ"},
        {"value": "716", "text": "ジンバブエ"},
        {"value": "724", "text": "スペイン"},
        {"value": "728", "text": "南スーダン"},
        {"value": "729", "text": "スーダン"},
        {"value": "732", "text": "西サハラ"},
        {"value": "740", "text": "スリナム"},
        {"value": "744", "text": "スヴァールバル諸島およびヤンマイエン島"},
        {"value": "748", "text": "エスワティニ"},
        {"value": "752", "text": "スウェーデン"},
        {"value": "756", "text": "スイス"},
        {"value": "760", "text": "シリア・アラブ共和国"},
        {"value": "762", "text": "タジキスタン"},
        {"value": "764", "text": "タイ"},
        {"value": "768", "text": "トーゴ"},
        {"value": "772", "text": "トケラウ"},
        {"value": "776", "text": "トンガ"},
        {"value": "780", "text": "トリニダード・トバゴ"},
        {"value": "784", "text": "アラブ首長国連邦"},
        {"value": "788", "text": "チュニジア"},
        {"value": "792", "text": "トルコ"},
        {"value": "795", "text": "トルクメニスタン"},
        {"value": "796", "text": "タークス・カイコス諸島"},
        {"value": "798", "text": "ツバル"},
        {"value": "800", "text": "ウガンダ"},
        {"value": "804", "text": "ウクライナ"},
        {"value": "807", "text": "北マケドニア"},
        {"value": "818", "text": "エジプト"},
        {"value": "826", "text": "イギリス"},
        {"value": "831", "text": "ガーンジー"},
        {"value": "832", "text": "ジャージー"},
        {"value": "833", "text": "マン島"},
        {"value": "834", "text": "タンザニア"},
        {"value": "840", "text": "アメリカ合衆国"},
        {"value": "850", "text": "アメリカ領ヴァージン諸島"},
        {"value": "854", "text": "ブルキナファソ"},
        {"value": "858", "text": "ウルグアイ"},
        {"value": "860", "text": "ウズベキスタン"},
        {"value": "862", "text": "ベネズエラ・ボリバル共和国"},
        {"value": "876", "text": "ウォリス・フツナ"},
        {"value": "882", "text": "サモア"},
        {"value": "887", "text": "イエメン"},
        {"value": "894", "text": "ザンビア"},
    ],
    residence: [
        {"value": "01", "text": "特定活動"},
        {"value": "02", "text": "企業内転勤"},
        {"value": "03", "text": "技術・人文知識・国際業務"},
        {"value": "10", "text": "高度専門職1号"},
        {"value": "11", "text": "高度専門職2号"},
        {"value": "20", "text": "永住者"},
        {"value": "21", "text": "永住者の配偶者"},
        {"value": "22", "text": "日本人の配偶者"},
        {"value": "90", "text": "日本籍"},
    ],
    nodeDispatch: [
        {"value": "01", "text": "上級の部門長に移動"},
        {"value": "02", "text": "当該部署の部門長に移動"},
    ],
    selfDispatch: [
        {"value": "01", "text": "自分で承認"},
        {"value": "02", "text": "上級の部門長に移動(上級組織ない場合自分で承認)"},
    ],
    observerDispatch: [
        {"value": "10", "text": "課長"},
        {"value": "12", "text": "部長"},
        {"value": "14", "text": "事業部長"},
        {"value": "16", "text": "本部長"},
        {"value": "90", "text": "全員"},
    ],
    bank_account_type: [
        {"value": "01", "text": "普通預金"},
        {"value": "02", "text": "定期預金"},
        {"value": "03", "text": "総合口座"},
        {"value": "04", "text": "当座預金"},
        {"value": "05", "text": "貯蓄預金"},
        {"value": "06", "text": "大口定期預金"},
        {"value": "07", "text": "積立定期預金"},
    ],
    years: [
        {"value": '2021', "text": '2021'},
        {"value": '2022', "text": '2022'},
        {"value": '2023', "text": '2023'},
        {"value": '2024', "text": '2024'},
        {"value": '2025', "text": '2025'},
    ],
    months: [
        {"value": '01', "text": '1月'},
        {"value": '02', "text": '2月'},
        {"value": '03', "text": '3月'},
        {"value": '04', "text": '4月'},
        {"value": '05', "text": '5月'},
        {"value": '06', "text": '6月'},
        {"value": '07', "text": '7月'},
        {"value": '08', "text": '8月'},
        {"value": '09', "text": '9月'},
        {"value": '10', "text": '10月'},
        {"value": '11', "text": '11月'},
        {"value": '12', "text": '12月'},
    ],
    vacation_reject_reason: [
        {
            "value": "該当社員しか担当出来る業務ではなく、かつその業務の期日が近い",
            "text": "該当社員しか担当出来る業務ではなく、かつその業務の期日が近い",
        },
        {
            "value": "この日、該当社員しか担当出来る業務がある。",
            "text": "この日、該当社員しか担当出来る業務がある。",
        },
        {
            "value": "多くの社員の有給取得日が重なり、代替人員を確保出来ない",
            "text": "多くの社員の有給取得日が重なり、代替人員を確保出来ない",
        },
    ],
}