<template>
  <b-form @submit.prevent>
    <validation-observer ref="observer">
      <b-row v-if="layout">
        <template v-for="(item, key) in layout">
          <template v-if="is_sub_layout(item)">
            <b-col v-for="(sub_name, key2) in item" :key="`sub-${key}-${key2}`" :sm="get_sm_cols(item)">
              <ControlCreator :field='get_field_by_name(sub_name)' :error='get_error_by_name(sub_name)' v-model="value[sub_name]" :data="value" @input="updateValue" />
            </b-col>
          </template>
          <b-col v-else :key="`col-${key}`" cols="12">
            <ControlCreator :field='get_field_by_name(item)' :error='get_error_by_name(item)' v-model="value[item]" :data="value" @input="updateValue" />
          </b-col>
        </template>
      </b-row>
      <b-row v-else>
        <b-col v-for="(field, key) in schema" :key="key" cols="12">
          <ControlCreator :field='field' :error='get_error_by_name(field.name)' v-model="value[field.name]" :data="value" @input="updateValue" />
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</template>
<script>
import { common } from '../../utils';
import ControlCreator from './ControlCreator';

export default {
  name: 'base-form',
  components: {
    ControlCreator,
  },
  props: {
    schema: { type: Array, default: () => [], required: true },
    layout: { type: Array, default: null },
    value: { type: Object, default: () => ({}) },
    error: { type: Object, default: () => ({}) },
  },
  methods: {
    get_sm_cols(item) {
      return parseInt(12 / item.length);
    },
    is_sub_layout(item) {
      return Array.isArray(item) && item.length > 0;
    },
    get_field_by_name(name) {
      return this.schema.find(i => i.name === name);
    },
    get_error_by_name(name) {
      if (this.error && name in this.error) {
        const errors = this.error[name];
        if (Array.isArray(errors) && errors.length > 0) {
          return errors[0]
        } else {
          return errors;
        }
      } else {
        return null;
      }
    },
    updateValue(value, name) {
      const data = common.clone(this.value);
      Object.assign(data, {[name]: value});
      this.$emit("input", data);
    },
    validate() {
      return this.$refs.observer.validate()
    },
  }
}
</script>