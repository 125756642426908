export const refresh_token = () => {
  try {
    return JSON.parse(localStorage.getItem('refresh'));
  } catch (e) {
    return null;
  }
};

export const access_token = () => {
  try {
    return JSON.parse(localStorage.getItem('token'));
  } catch (e) {
    return null;
  }
};

export const session_id = () => {
  try {
    return JSON.parse(localStorage.getItem('sessionid'));
  } catch (e) {
    return null;
  }
};

export function authHeader() {
  // return authorization header with jwt token
  let token = access_token();
  const sessionid = session_id();
  const headers = {
    'Accept': 'application/json',
    "Content-Type": "application/json; charset=utf-8",
  }

  if (token && sessionid) {
    return Object.assign({}, headers, {
      'Authorization': 'JWT ' + token,
      "X-Cas-Token": sessionid,
    });
  } else if (token) {
    return Object.assign({}, headers, {
      'Authorization': 'JWT ' + token,
    });
  } else {
    return headers;
  }
}
