import Vue from 'vue';
import Vuex, { createLogger } from 'vuex'

import { company } from './company.module';
import { user } from './user.module'
import { server } from './server.module'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createLogger()],
  modules: {
    account: user,
    server,
    company,
  },
});
