import { ajax, common, config } from '../utils/index'

export const userService = {
  login,
  logout,
  casLogin,
  larkLogin,
  casLogout,
  getMe,
  getStatistics,
  getOrganizations,
  getNotifications,
  readNotification,
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        username,
        password
      }
    )
  };

  return fetch(config.api.authenticate.login, requestOptions)
    .then(handleNativeResponse)
    .then(data => {
      if (data.access) {
        localStorage.setItem('token', JSON.stringify(data.access));
      }
      if (data.refresh) {
        localStorage.setItem('refresh', JSON.stringify(data.refresh));
      }
      return data;
    })
}

function logout() {
  // ログアウト時にはローカルストレージからuserアイテムを削除する
  return ajax.fetchPost(config.api.account.logout).then(() => (
    localStorage.removeItem('token')
  ));
}

function casLogin(service, ticket) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        service,
        ticket
      }
    )
  };

  return fetch(config.api.authenticate.cas_login, requestOptions)
    .then(handleResponse)
    .then(data => {
      if (data.access) {
        localStorage.setItem('token', JSON.stringify(data.access));
      }
      if (data.refresh) {
        localStorage.setItem('refresh', JSON.stringify(data.refresh));
      }
      if (data.sessionid) {
        // JWTログアウト時、CASのTicketはセッションに保存されている。
        localStorage.setItem('sessionid', JSON.stringify(data.sessionid));
      }
      return data;
    })
}

function larkLogin(code) {
  console.log(code)
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      {
        code
      }
    )
  };
  console.log(requestOptions)

  return fetch(config.api.authenticate.lark_login, requestOptions)
    .then(handleResponse)
    .then(data => {
      if (data.access) {
        localStorage.setItem('token', JSON.stringify(data.access));
      }
      if (data.refresh) {
        localStorage.setItem('refresh', JSON.stringify(data.refresh));
      }
      if (data.sessionid) {
        // JWTログアウト時、CASのTicketはセッションに保存されている。
        localStorage.setItem('sessionid', JSON.stringify(data.sessionid));
      }
      return data;
    })
}

function casLogout() {
  // ログアウト時にはローカルストレージからuserアイテムを削除する
  return ajax.fetchPost(config.api.account.cas_logout).then((data) => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('sessionid');
    return data;
  });
}

function getMe() {
  return ajax.fetchPost(config.api.account.me);
}

function getStatistics() {
  return ajax.fetchGet(config.api.account.statistics);
}

function getOrganizations() {
  return ajax.fetchGet(config.api.employee.organization_list);
}

function getNotifications() {
  return ajax.fetchGet(config.api.employee.notification_list);
}

function readNotification(pk, is_read) {
  return ajax.fetchPut(common.formatStr(config.api.employee.notification_read, pk), { is_read });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }

      let errors = null;
      if (data && data.message) {
        errors = data.message
      } else if (common.isJSON(data)) {
        errors = data;
      } else {
        errors = response.statusText;
      }
      return Promise.reject(errors);
    }

    return data;
  });
}


function handleNativeResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      let errors = null;
      if (data && data.message) {
        errors = data.message
      } else if (common.isJSON(data)) {
        errors = data;
      } else {
        errors = response.statusText;
      }
      return Promise.reject(errors);
    }

    return data;
  });
}
