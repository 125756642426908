import BaseHeader from '@/components/BaseHeader';
import BaseButton from '@/components/BaseButton';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseTextarea from '@/components/Inputs/BaseTextarea.vue';
import BaseDatePicker from '@/components/Inputs/BaseDatePicker';
import BaseAutocomplete from '@/components/Inputs/BaseAutocomplete';
import BaseFile from '@/components/Inputs/BaseFile';
import BaseSelect from '@/components/Inputs/BaseSelect';
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox";
import BaseModal from "@/components/BaseModal";
import BaseForm from "@/components/Forms/BaseForm";
import AttendanceForm from "@/components/Forms/AttendanceForm";
import AssetsForm from "@/components/Forms/AssetsForm";
import Stepper from '@/components/Stepper.vue';
import FlowChart from '@/components/FlowChart.vue';
import Multiselect from 'vue-multiselect';
import { extend, localize } from 'vee-validate';
import lang from "vee-validate/dist/locale/ja.json";
import { required, min, max, email, confirmed, size } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);
extend("size", size);

localize("ja", lang);

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseAutocomplete.name, BaseAutocomplete);
    Vue.component(Card.name, Card);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseTextarea.name, BaseTextarea);
    Vue.component(BaseDatePicker.name, BaseDatePicker);
    Vue.component(BaseFile.name, BaseFile);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseModal.name, BaseModal);
    Vue.component(BaseForm.name, BaseForm);
    Vue.component(AttendanceForm.name, AttendanceForm);
    Vue.component(AssetsForm.name, AssetsForm);
    Vue.component(Stepper.name, Stepper);
    Vue.component(FlowChart.name, FlowChart);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
    Vue.component('multiselect', Multiselect)
  }
};

export default GlobalComponents;
