import VueRouter from "vue-router";

import CompanyLayout from '../views/layout/CompanyLayout';
import MainLayout from '../views/layout/MainLayout';
import LoginNative from '../views/account/Login';
import LoginCAS from '../views/account/LoginCAS';
import store from '../store';
import { access_token } from "../utils/authHeader";

const routes = [
  {
    name: 'login',
    path: '/login',
    component: LoginCAS,
    meta: { requiresLogin: false },
  },
  {
    name: 'login-native',
    path: '/login-native',
    component: LoginNative,
    meta: { requiresLogin: false },
  },
  {
    name: 'root',
    path: '/',
    component: MainLayout,
    redirect: 'home',
    children: [
      {
        name: 'home',
        path: '/home',
        component: () => import('../views/account/Home.vue'),
      },
      {
        name: 'profile',
        path: '/profile',
        component: () => import('../views/account/Profile.vue'),
      },
      {
        name: 'contacts',
        path: '/contacts',
        component: () => import('../views/account/Contacts.vue'),
      },
      {
        name: 'band-accounts',
        path: '/band-accounts',
        component: () => import('../views/employee/bank_accounts.vue'),
      },
      {
        name: 'payment-slip',
        path: '/payment-slip',
        component: () => import('../views/employee/payment_slip.vue'),
      },
      {
        name: 'withholding',
        path: '/withholding',
        component: () => import('../views/employee/withholding.vue'),
      },
      {
        name: 'vacations',
        path: '/vacations',
        component: () => import('../views/employee/vacations.vue'),
      },
      {
        name: 'proxy-list',
        path: '/proxies',
        component: () => import('../views/account/ProxyList.vue')
      },
      {
        name: 'proxy-add',
        path: '/proxies/add',
        component: () => import('../views/account/ProxyForm.vue')
      },
      {
        name: 'proxy-edit',
        path: '/proxies/:id',
        component: () => import('../views/account/ProxyForm.vue')
      },
      {
        name: 'manual',
        path: '/manual',
        component: () => import('../views/manual/manual_list.vue'),
      },
      {
        name: 'company',
        path: '/company',
        component: CompanyLayout,
        children: [
          {
            name: 'company-calendar',
            path: '/company/calendar',
            component: () => import('../views/company/calendar.vue'),    
          },
          {
            name: 'company-organization-chart',
            path: '/company/organization-chart',
            component: () => import('../views/company/organization_chart.vue'),
          },
        ]
      },
      {
        name: 'workflows',
        path: '/workflow/workflows',
        component: () => import('../views/workflow/workflow_list.vue'),
      },
      {
        name: 'workflow-detail',
        path: '/workflow/workflows/:id',
        component: () => import('../views/workflow/workflow_detail.vue'),
      },
      {
        name: 'workflow-nodes',
        path: '/workflow/nodes',
        component: () => import('../views/workflow/node_list.vue'),
      },
      {
        name: 'task-workflows',
        path: '/task/workflows',
        component: () => import('../views/task/workflow_list.vue'),
      },
      {
        name: 'task-create',
        path: '/task/workflows/:id/create',
        component: () => import('../views/task/task_create.vue'),
      },
      {
        name: 'tasks-unresolved',
        path: '/task/tasks/unresolved',
        component: () => import('../views/task/unresolved_list.vue'),
      },
      {
        name: 'task-unresolved-detail',
        path: '/task/tasks/unresolved/:id',
        component: () => import('../views/task/task_detail.vue'),
      },
      {
        name: 'tasks-approval',
        path: '/task/tasks/approval',
        component: () => import('../views/task/approval_list.vue'),
      },
      {
        name: 'task-approval-detail',
        path: '/task/tasks/approval/:task_id/nodes/:node_id',
        component: () => import('../views/task/approval_detail.vue'),
      },
      {
        name: 'tasks-approval-finished',
        path: '/task/tasks/approval-finished',
        component: () => import('../views/task/approval_finished.vue'),
      },
      {
        name: 'tasks-approval-finished-detail',
        path: '/task/tasks/approval-finished/:task_id',
        component: () => import('../views/task/finished_detail.vue'),
      },
      {
        name: 'tasks-finished',
        path: '/task/tasks/finished',
        component: () => import('../views/task/finished_list.vue'),
      },
      {
        name: 'task-finished-detail',
        path: '/task/tasks/finished/:task_id',
        component: () => import('../views/task/finished_detail.vue'),
      },
      {
        name: 'tasks-observer',
        path: '/task/tasks/observer',
        component: () => import('../views/task/observer_list.vue'),
      },
      {
        name: 'tasks-observer-detail',
        path: '/task/tasks/observer/:task_id',
        component: () => import('../views/task/observer_detail.vue'),
      },
      {
        name: 'expense-uploads',
        path: '/expense/uploads',
        component: () => import('../views/expense/upload.vue'),
      },
      {
        name: 'expense-upload-list',
        path: '/expense/upload/',
        component: () => import('../views/expense/upload_list.vue'),
      },
      {
        name: 'expense-upload-detail',
        path: '/expense/upload/:id',
        component: () => import('../views/expense/upload_detail.vue'),
      },
      {
        name: 'expense-attendance',
        path: '/expense/attendance',
        component: () => import('../views/expense/attendance.vue'),
      },
      {
        name: 'attendance-pdf',
        path: '/attendance/pdf',
        component: () => import('../views/attendance/attendance_pdf.vue'),
      },
      {
        name: 'attendance-list',
        path: '/employee/attendance/:id',
        component: () => import('../views/attendance/attendance_list.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../views/common/NotFound.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior: async(to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    const findEl = async (hash, x) => {
      return document.querySelector(hash) ||
        new Promise((resolve, reject) => {  // eslint-disable-line
          if (x > 50) {
            return resolve();
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1));
          }, 500);
        });
    }
    if (to.hash) {
      const el = await findEl(to.hash);
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
      } else {
        return window.scrollTo(0, el.offsetTop);
      }
    }
    return {x: 0, y: 0};
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch('server/setStatusCode', 200);
  if (!to.matched.some(record => record.meta.requiresLogin === false) && !access_token()) {
    next({ name: 'login', query: { next: to.fullPath } })
  } else {
    next()
  }
});

export default router