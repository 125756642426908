import { companyService } from './company.service'

const state = {
  name: null,
  file_calendar: {
    images: [],
  }
};

const actions = {
  async getCompanyInfo({ commit }) {
    return companyService.getCompanyInfo().then(data => {
      commit('setCompanyInfo', data);
    });
  },
};

const mutations = {
  setCompanyInfo(state, data) {
    Object.assign(state, data);
  },
};

const getters = {
};

export const company = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};