<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    :class="{'navbar-dark': type === 'default'}"
  >
    <a href="javascript:void(0)"
      aria-current="page"
      class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"
    >
      {{pageName}}
    </a>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a class="nav-link" @click="showSidebar(true)" data-action="search-show" data-target="#navbar-search-main">
          <i class="fas fa-bars"></i>
        </a>
      </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <!-- <b-form
        class="navbar-search form-inline mr-sm-3"
        :class="{'navbar-search-dark': type === 'default', 'navbar-search-light': type === 'light'}"
        id="navbar-search-main"
      >
        <b-form-group class="mb-0">
          <b-input-group class="input-group-alternative input-group-merge">
            <b-form-input placeholder="Search" type="text"> </b-form-input>
            <div class="input-group-append">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
          </b-input-group>
        </b-form-group>
      </b-form> -->
      <li>
        <a
          href="javascript:void(0)"
          class="btn-floating deep-purple mr-2 nav-icon"
          @click.prevent
          v-b-toggle.sidebar-notifications-backdrop
          slot="title-container"
        >
          <b-icon icon="bell-fill" class="rounded-circle" variant="secondary"></b-icon>
          <b-badge v-if="unreadNotifications.length > 0" pill variant="danger">{{ unreadNotifications.length }}</b-badge>
        </a>
        <b-sidebar
          id="sidebar-notifications-backdrop"
          backdrop
          right
          shadow
          z-index="9999"
          @change="openSidebar"
          bg-variant="white"
        >
          <template slot="title">
            <b-form-checkbox v-model="unread" name="check-button" switch>
              {{ unread ? '未読' : '既読' }}
            </b-form-checkbox>
          </template>
          <div v-if="notifications.length > 0">
            <div v-for="(n, key) in notificationsOnShow" :key="key" class="notification px-3 py-2">
              <div class="notification-title">
                <router-link :to="getNotificationUrl(n.forward)" class="title" @click.native="setNotificationRead(n, true)">{{ n.title }}</router-link>
                <a href="javascript:void(0)" class="action" @click="setNotificationRead(n, unread)">
                  <b-badge :variant="unread ? 'danger' : 'info'">{{ unread ? '未読' : '既読' }}</b-badge>
                </a>
              </div>
              <div class="notification-content">{{ n.content }}</div>
              <div class="notification-tail">{{ getNotificationDt(n.created_dt) }}</div>
            </div>
          </div>
          <div v-else class="notification px-3 py-2">
            メッセージがありません。
          </div>
        </b-sidebar>
      </li>
      <li>
        <b-link id="id-top-calendar"
          :to="{ name: 'company-calendar' }"
          class="icon-shape text-white shadow"
        >
          <b-icon icon="calendar-week"></b-icon>
        </b-link>
        <b-tooltip target="id-top-calendar" triggers="hover">
          年間就業カレンダー
        </b-tooltip>
      </li>
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="javascript:void(0)" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <b-avatar size="36px" :src="avatar"/>
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ fullName }}</span>
            </b-media-body>
          </b-media>
        </a>
        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </b-dropdown-header>
          <template v-if="isEmployee">
            <b-dropdown-item :to="{ name: 'profile' }">
              <i class="fas fa-user-circle"></i>
              <span>プロフィール</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'contacts' }">
              <span class="no-icon"></span>
              <span>緊急連絡先</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'band-accounts' }">
              <span class="no-icon"></span>
              <span>銀行口座</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'payment-slip' }">
              <span class="no-icon"></span>
              <span>給与明細一覧</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'withholding' }">
              <span class="no-icon"></span>
              <span>源泉票一覧</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'vacations' }">
              <span class="no-icon"></span>
              <span>休暇履歴</span>
            </b-dropdown-item>
            <div class="dropdown-divider"></div>
            <b-dropdown-item :to="{ name: 'proxy-list' }">
              <i class="fas fa-people-arrows"></i>
              <span>承認代理</span>
            </b-dropdown-item>
          </template>
          <div class="dropdown-divider"></div>
          <b-dropdown-item @click.prevent="signout" v-if="!isLark">
            <i class="fas fa-sign-out-alt"></i>
            <span>ログアウト</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { BaseNav } from '@/components';
import { common } from '../../utils';

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: 'default',  // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    ...mapState('account', ['user', 'notifications']),
    pageName() {
      const { name } = this.$route;
      return common.getPageName(name);
    },
    avatar() {
      return this.user && this.user.me ? this.user.me.avatar : null;
    },
    isEmployee() {
      return this.user && this.user.me && this.user.me.employee ? true : false;
    },
    fullName() {
      return this.user && this.user.me ? this.user.me.full_name : null;
    },
    unreadNotifications() {
      return this.notifications.filter(i => i.is_read === false);
    },
    notificationsOnShow() {
      return this.notifications.filter(i => i.is_read === !this.unread);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      unread: true,
      isLark: false,
    };
  },
  mounted() {
    this.checkIsLark();
  },
  methods: {
    ...mapActions('account', ['casLogout', 'showSidebar', 'readNotification']),
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    getNotificationDt(str_dt) {
      return common.formatDate(new Date(str_dt), 'YYYY-MM-DD hh:mm')
    },
    getNotificationUrl(url) {
      return common.getRelativeUrl(url);
    },
    setNotificationRead(notification, is_read) {
      if (notification.is_read !== is_read) {
        this.readNotification({ notification_id: notification.id, is_read });
      }
    },
    openSidebar(visible) {
      const classes = document.querySelector("body").className;
      if (visible) {
        document.querySelector("body").className = `${classes} modal-open`;
      } else {
        document.querySelector("body").className = classes.replace('modal-open', '');
      }
    },
    signout() {
      this.casLogout();
    },
    checkIsLark() {
      if (window.h5sdk) {
        this.isLark = true;
      }
    },
  }
};
</script>
<style scoped>
.no-icon {
  display: inline-block;
  width: 32px;
}
</style>