import Vue from 'vue';
import queryString from 'query-string';
import { vsprintf } from 'sprintf-js';
import cloneDeep from 'lodash/cloneDeep';
import constant from './constant';

export default {
  /**
   * JSONをコピーする
   * @param {JSON} data コピーしようとするJSONデータ
   */
  clone: function(data) {
    return cloneDeep(data);
  },

  /**
   * オブジェクトは空白なのか
   * @param {Object} obj 
   */
  isEmpty: function(obj) {
    if (obj === null || obj === undefined || obj === '') {
      return true;
    } else if (Array.isArray(obj)) {
      return obj.length === 0;
    } else if (typeof obj === 'number') {
      return false;
    }
    for(var key in obj) {
      // if(obj.hasOwnProperty(key))
      //   return false;
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }
    return true;
  },

  /**
   * 文字列の両端に特定の文字を消す
   * @param {String} s 文字列
   * @param {String} c 置換する文字列
   */
  trim: function(s, c) {
    if (c === "]") c = "\\]";
    if (c === "\\") c = "\\\\";
    return s.replace(new RegExp(
      "^[" + c + "]+|[" + c + "]+$", "g"
    ), "");
  },

  /**
   * JSONかどうかを判定する関数
   * @param {Object} arg 
   */
  isJSON: function(arg) {
    return typeof arg === 'object';
  },

  addMonths: function(date, months=1) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    const resultDate = new Date(date.getTime());
    resultDate.setMonth(date.getMonth() + months);
    if (date.getDate() > resultDate.getDate()) {
      resultDate.setDate(0);
    }
    return resultDate;
  },

  addDays: function(date, days) {
    if (typeof date === "string") {
      date = new Date(date);
    }
    date.setDate(date.getDate() + days);
    return date;
  },

  /**
   * 日付をフォーマットする
   * @param {Date} date 
   * @param {String} format 
   */
  formatDate: function (date, format) {
    if (!format) {
        format = 'YYYY-MM-DD hh:mm:ss.SSS';
    }
    if (!date) {
      return null;
    } else if (typeof date === "string") {
        date = new Date(date);
    }
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
        var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
        var length = format.match(/S/g).length;
        for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  },

  /**
   * 文字列をフォーマットする
   * @param {String} format 
   *
   * 使用方法：utils.format('This is argument: %s', arg1);
   */
  formatStr: function(format) {
    if (!format) {
      return null;
    } else if (arguments && arguments.length === 2 && this.isJSON(arguments[1])) {
      return vsprintf(format, arguments[1]);
    } else {
      var i = 0,
        j = 0,
        r = "",
        next = function(args){
          j += 1; i += 1;
          return args[j] !== void 0 ? args[j] : "";
        };

      for(i=0; i<format.length; i++){
        if(format.charCodeAt(i) === 37){
          switch(format.charCodeAt(i+1)){
            case 115: r += next(arguments); break;
            case 100: r += Number(next(arguments)); break;
            default: r += format[i]; break;
          }
        } else {
          r += format[i];
        }
      }
      return r;
    }
  },

  /**
   * ＵＲＬにパラメーターを追加する
   * @param {String} url ＵＲＬ
   * @param {Object} data JSON型のパラメーター
   */
addUrlParameter: function(url, data) {
    url = this.trim(this.trim(url, '?'), '&');
    const params = queryString.stringify(data);
    if (url.indexOf('?') < 0) {
      return `${url}?${params}`;
    } else {
      return `${url}&${params}`;
    }
  },

  /**
   * URLのパラメーターからJSONを取得
   * @param {String} qs URL
   */
  parseQueryString: function(qs) {
    return queryString.parse(qs);
  },

  /**
   * ファイルをBase64に変換する.
   */
  fileToBase64: (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }),
  
  /**
   * 文字列をBase64に変換する.
   * @param {String} s 変換しようとする文字列
   */
  strToBase64: function(s) {
    return btoa(unescape(encodeURIComponent(s)));
  },

  getCookie: function(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  },

  /**
   * 年齢を取得する
   * @param {String} birthday 誕生日
   * @returns 
   */
  getAge: function(birthday) {
    if (!(birthday instanceof Date)) {
      birthday = new Date(birthday);
    }
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

  /**
   * 選択肢から該当値のテキストを取得する
   * @param {*} value 値
   * @param {*} choice 選択肢
   * @returns 
   */
  getChoiceText: function(value, choice) {
    const option = choice.find(i => i.value === value);
    return option ? option.text : value;
  },

  /**
   * ファイルの拡張子を取得する
   * @param {String} filename ファイル名称
   */
   getFileExtention: function(filename) {
    if (!filename) {
      return "";
    }
    const ext = filename.split('.').pop();
    if (ext !== "" && ext !== filename) {
      return `.${ext}`.toLowerCase();
    } else {
      return "";
    }
  },

  /**
   * ファイルは画像であるかどうか
   * @param {String} filename ファイル名称
   */
  isImage: function(filename) {
    const ext = this.getFileExtention(filename);
    return ['.png', '.jpg', '.jpeg', '.gif', '.tiff', '.bmp', '.svg', '.epsf', '.pict', '.ico', '.icns'].indexOf(ext) >= 0;
  },

  /**
   * PDFファイルであるかどうか
   * @param {String} filename ファイル名称
   */
  isPdf: function(filename) {
    const ext = this.getFileExtention(filename);
    return ['.pdf'].indexOf(ext) >= 0;
  },

  /**
   * エクセルファイルであるかどうか
   * @param {String} filename ファイル名称
   */
  isExcel: function(filename) {
    const ext = this.getFileExtention(filename);
    return ['.xls', '.xlsx', '.xlsm'].indexOf(ext) >= 0;
  },

  /**
   * Base64をBlobに変換する
   * @param {Base64} base64 Base64
   * @param {String} mime_ctype 
   */
   toBlob: function(base64, mime_ctype='application/octet-stream') {
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    try{
      var blob = new Blob([buffer.buffer], {
        type: mime_ctype
      });
    } catch (e) {
      return false;
    }
    return blob;
  },

  /**
   * Blobファイルをダウンロード
   * @param {Blob} blob 
   * @param {String} filename ファイル名
   */
  downloadBlob: function(blob, filename) {
    const csvURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  },

  loading: function() {
    const musk = document.createElement('div');
    musk.className = "musk";
    document.body.appendChild(musk);
    document.body.className = 'musk';

    const instance = new Vue({
      render: function(createElement) {
        return createElement('b-spinner', { variant: "primary", label: "Loading" });
      },
    });
    instance.$mount();
    musk.appendChild(instance.$el);
  },

  loaded: function() {
    const loading = document.querySelector("div.musk");
    if (loading) {
      loading.remove();
    }
    if (document.querySelector("div.musk") === null) {
      document.body.className = '';
    }
  },

  /**
   * ルーター名によって画面名称を取得する
   * @param {String} name ルーター名
   * @returns 
   */
  getPageName: function(name) {
    if (name in constant.PAGE_TITLE) {
      return constant.PAGE_TITLE[name];
    } else {
      return name;
    }
  },

  /**
   * Base64ファイルのサイズを取得（単位：バイト数）
   * @param {String} b64string Base64ファイルの文字列
   */
   getB64FileSize: function(b64string) {
    if (b64string && b64string.indexOf(";base64,") >= 0) {
      const arr = b64string.split(';base64,');
      const data = arr[1];
      return data.length * 3 / 4 - (data.match(/=/g) || []).length;
    } else {
      return 0;
    }
  },

  /**
   * 相対URLを取得する
   * @param {String} absoluteUrl 絶対的パス
   * @returns 
   */
  getRelativeUrl: function(absoluteUrl) {
    return absoluteUrl.replace(/^.*\/\/[^/]+/, '');
  },

  /**
   * 数字の先頭に0をつける
   * @param {Integer} num 数字
   * @param {Integer} size 桁数
   */
  pad: function(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length-size);
  },

  /**
   * 現在の年を取得
   */
  getCurrentYear: function() {
    const d = new Date();
    return d.getFullYear() + '';
  },

  /**
   * 現在の月を取得
   */
  getCurrentMonth: function() {
    const d = new Date();
    // return (d.getMonth() + 1) + '';
    return this.pad(d.getMonth() + 1, 2);
  },

  /**
   * 先月を取得
   * データ形式：{year: YYYY, month: MM}
   * @param {Date}} date 指定日付
   */
  getPrevMonthDict: function(date) {
    if (!(date instanceof Date)) {
      date = new Date();
    }
    const d = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return {
      'year': d.getFullYear() + '',
      'month': this.pad(d.getMonth() + 1, 2),
    }
  },

  /**
   * 次月を取得
   * データ形式：{year: YYYY, month: MM}
   * @param {Date}} date 指定日付
   */
  getNextMonthDict: function(date) {
    if (!(date instanceof Date)) {
      date = new Date();
    }
    const d = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return {
      'year': d.getFullYear() + '',
      'month': this.pad(d.getMonth() + 1, 2),
    }
  },

  /**
   * 2つの期間中の日数を取得する
   * @param {Date} d1 開始日
   * @param {Date} d2 終了日
   * @returns 秒数
   */
  getDateDiff: function(d1, d2) {
    if (typeof d1 === 'string') {
      d1 = new Date(d1);
    }
    if (typeof d2 === 'string') {
      d2 = new Date(d2);
    }
    if (!d1 || !d2) {
      return null;
    }
    if (isNaN(d1) || isNaN(d2)) {
      return null;
    }
    return (d2 - d1) / 1000;
  },

  /**
   * 2つの期間中の日数を取得する
   * @param {Date} d1 開始日
   * @param {Date} d2 終了日
   * @returns 
   */
  getDaysDiff: function(d1, d2) {
    const seconds = this.getDateDiff(d1, d2);
    return Math.abs(seconds / (60 * 60 * 24));
  },

  downloadCSV: function (csvContent, filename) {
    if (filename) {
      filename += '.csv';
    } else {
      filename = 'export.csv';
    }
    let link = document.createElement('a');
    let bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    let blob = new Blob([bom, csvContent], { type: 'text/csv' });
    link.setAttribute('download', filename);
    if (window.webkitURL && window.webkitURL.createObjectURL) {
      // for chrome (and safari)
      link.setAttribute('href', window.webkitURL.createObjectURL(blob));
      link.click();
    } else if (window.URL && window.URL.createObjectURL) {
      // for firefox
      link.setAttribute('href', window.URL.createObjectURL(blob));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert('サポートしないブラウザです。')
    }
  },

  arrayToCSV: function(array) {
    let lineArray = [];
    array.forEach(function (infoArray, index) {  // eslint-disable-line
      let line = infoArray.join(',');
      lineArray.push(`${line}`);
    });
    let csvContent = lineArray.join("\n");
    return csvContent;
  },

}