<template>
  <div>
    <NotFound v-if="statusCode === 404" />
    <ServerError v-else-if="statusCode === 500" />
    <div v-else>
      <Sidebar/>
      <div class="main-content">
        <Topbar :type="$route.meta.navbarType"/>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <ContentFooter v-if="!$route.meta.hideFooter"></ContentFooter>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import ContentFooter from './ContentFooter';
import NotFound from '../common/NotFound.vue';
import ServerError from '../common/ServerError.vue';
import { FadeTransition } from 'vue2-transitions';
import { ajax, config, firebase, firebaseDeviceInfo } from '../../utils/index';

export default {
  components: {
    Topbar,
    Sidebar,
    ContentFooter,
    FadeTransition,
    NotFound,
    ServerError,
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('server', ['statusCode']),
  },
  created: function() {
    this.getMe().then(() => {
      // モバイルなどFirebaseサポートしないデバイスがあるので、判断は必要
      if (firebase) {
        firebase.getToken({ vapidKey: config.vapidKey })
          .then((currentToken) => {
            if (currentToken) {
              console.log('client token', currentToken);
              this.sendTokenToServer(currentToken);
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      }
      this.getStatistics();
      this.getOrganizations();
      this.getNotifications();
      this.getCompanyInfo();
    });
  },
  methods: {
    ...mapActions('account', ['getMe', 'getStatistics', 'getOrganizations', 'addFirebaseDevice', 'getNotifications']),
    ...mapActions('company', ['getCompanyInfo']),
    sendTokenToServer(token) {
      if (!this.user) {
        return;
      } else if (this.user.me.devices.indexOf(token) >= 0) {
        console.log('デバイス既に登録済み！');
        return;
      }
      const vm = this;
      ajax.fetchPost(config.api.master.register_firebase_device, {
        token,
        description: firebaseDeviceInfo,
        user: vm.user.me.id,
      }).then(() => {
        console.log('デバイス登録成功！');
        vm.addFirebaseDevice(token);
      }).catch(
        () => console.log('デバイス登録失敗！')
      );
    },
  }
}
</script>