<template>
  <b-table-simple>
    <b-tbody>
      <b-tr v-if="task.project_id">
        <b-th class="text-right">所属案件</b-th>
        <b-td>{{ task.project_name }}</b-td>
      </b-tr>
      <b-tr v-if="task.org_id">
        <b-th class="text-right">所属組織</b-th>
        <b-td>{{ task.org_name }}</b-td>
      </b-tr>
      <b-tr>
        <b-th class="text-right">申請者</b-th>
        <b-td>{{ task.employee_name }}</b-td>
      </b-tr>
      <b-tr v-for="(field, key) in fields" :key="key">
        <b-th class="text-right">{{ field.label }}</b-th>
        <b-td v-if="field.data_type=='file'">
          <a href="javascript:void(0)" @click="previewFile(field.attachment)">{{field.attachment_name}}</a>
        </b-td>
        <b-td v-else>{{ get_field_text(field) }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import { viewAttachment } from "./api";

export default {
  props: {
    task: { type: Object },
    fields: { type: [Array, Object] },
  },
  methods: {
    previewFile(file_uuid) {
      return viewAttachment(file_uuid, this);
    },
    get_field_text(field) {
      if (field.choices) {
        const choice = field.choices.find(i => i.value === field.value);
        return choice ? choice.text : field.value;
      } else {
        return field.value;
      }
    },
  }
}
</script>