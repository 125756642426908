var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',[_vm._t("label",[(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required || _vm.rules.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("（*）")]):_vm._e()]):_vm._e()]),_c('div',{class:[
     {'input-group': _vm.hasIcon},
     {'focused': _vm.focused},
     {'input-group-alternative': _vm.alternative},
     {'has-label': _vm.label || _vm.$slots.label},
     _vm.inputGroupClasses
     ]},[(_vm.prependIcon || _vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("prepend",[_c('i',{class:_vm.prependIcon})])],2)]):_vm._e(),_vm._t("default",[_c('b-form-textarea',_vm._g(_vm._b({staticClass:"form-control",class:[{'is-valid': valid && validated && _vm.successMessage}, {'is-invalid': invalid && validated}, _vm.inputClasses],attrs:{"value":_vm.value,"name":_vm.label,"valid":valid,"required":_vm.required,"max-rows":"10"}},'b-form-textarea',_vm.$attrs,false),_vm.listeners))],null,_vm.slotData),(_vm.appendIcon || _vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("append",[_c('i',{class:_vm.appendIcon})])],2)]):_vm._e(),_vm._t("infoBlock")],2),_vm._t("success",[(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]),_vm._t("error",[(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]),_vm._l((_vm.outsideErrors),function(err,key){return _c('div',{key:key,staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(err)+" ")])})],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }