<template>
  <validation-provider
    :rules="`size:${fileSizeLimit}`"
    :name="label || name"
    v-bind="$attrs"
    v-slot="{errors, valid, invalid, validated, validate}"
  >
    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}{{rules.required?"（*）" : ""}}
        </label>
      </slot>
      <b-form-file
        :placeholder="placeholder"
        class="mb-2"
        @change="validate"
        @input="updateValue"
        :multiple="multiple"
        :accept="accept"
        :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated}, inputClasses]"
      >
      </b-form-file>
      <div :class="helpClasses" v-if="helpText">
        {{ helpText }}
      </div>
      <div v-if="filename&&file_uuid">
        <a href="javascript:void(0)" @click="handlePreview">{{filename}}</a>
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{successMessage}}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<script>
import { common, config } from '../../utils';

export default {
  inheritAttrs: false,
  name: "base-file",
  props: {
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    helpClasses: {
      type: String,
      description: "Help text css classes",
      default: "form-control-help"
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    placeholder: {
      type: String,
      description: "Input label (text before input)"
    },
    filename: { type: String },
    file_uuid: { type: String },
    multiple: { type: Boolean, default: false },
    accept: { type: String, default: null },
    helpText: { type: String },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    fileSizeLimit() {
      // vee-validateの単位はKBです。
      return config.fileSizeLimit / 1024;
    }
  },
  methods: {
    async updateValue(value) {
      if (this.multiple) {
        const files = [];
        value.map(async i => {
          const result = await common.fileToBase64(i);
          files.push({ name: i.name, content: `name:${common.strToBase64(i.name)};${result}`});
          return i;
        });
        this.$emit("input", files);
      } else if (value) {
        const result = await common.fileToBase64(value);
        this.$emit("input", `name:${common.strToBase64(value.name)};${result}`);
      } else {
        this.$emit("input", this.file_uuid || null);
      }
    },
    handlePreview() {
      if ('preview' in this.$listeners) {
        this.$emit('preview', this.file_uuid, this)
      }
    }
  }
}
</script>