import { common } from ".";
import { authHeader } from "./authHeader";
import router from '../router/index'
import store from '../store/index';

export default {

  getMethod: function(name) {
    switch (name) {
      case 'GET':
        return this.fetchGet;
      case 'POST':
        return this.fetchPost;
      case 'DELETE':
        return this.fetchDelete;
      case 'PUT':
        return this.fetchPut;
      case 'OPTIONS':
        return this.fetchOptions;
      default:
        throw Error('UNKNOWN METHOD NAME!');
    }
  },

  /**
   * 
   * @param {String} url ＵＲＬ
   * @param {Object} params パラメーター
   */
  fetchGet: function(url, params) {
    return this.fetchCommon(url, 'GET', params);
  },

  /**
   * 
   * @param {String} url ＵＲＬ
   * @param {Object} params パラメーター
   */
  fetchPost: function(url, params) {
    return this.fetchCommon(url, 'POST', params);
  },

  /**
   * 
   * @param {String} url ＵＲＬ
   * @param {Object} params パラメーター
   */
  fetchPatch: function(url, params) {
    return this.fetchCommon(url, 'PATCH', params);
  },

  /**
   * 
   * @param {String} url ＵＲＬ
   * @param {Object} params パラメーター
   */
  fetchDelete: function(url, params) {
    return this.fetchCommon(url, 'DELETE', params);
  },

  /**
   * 
   * @param {String} url ＵＲＬ
   * @param {Object} params パラメーター
   */
  fetchPut: function(url, params) {
    return this.fetchCommon(url, 'PUT', params);
  },

  /**
   * 
   * @param {String} url ＵＲＬ
   * @param {Object} params パラメーター
   */
  fetchOptions: function(url) {
    return this.fetchCommon(url, 'OPTIONS');
  },

  fetchBinary: function(url) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
      // credentials: 'same-origin',
    };
    return fetch(url, requestOptions)
      .then(this.handleStatus)
      .then(function(res) {
        return res.blob();
      });
  },

  /**
   * ＡＰＩを呼び出す
   * @param {String} url ＵＲＬ
   * @param {String} method GET|POST|PUT|DELETE
   * @param {Object} params パラメーター
   */
  fetchCommon: function(url, method, params) {
    const requestOptions = {
      method: method,
      headers: authHeader(),
      // credentials: 'same-origin',
    };
    if (params && !common.isEmpty(params)) {
      if (method === 'GET') {
        url = common.addUrlParameter(url, params);
      } else if (['POST', 'PUT'].indexOf(method) >= 0 && 'blob' in params) {
        requestOptions['body'] = params['blob'];
        requestOptions.headers['Content-Type'] = params.type || 'multipart/form-data';
      } else {
        requestOptions['body'] = JSON.stringify(params)
      }
    }

    url = common.addUrlParameter(url, {'format': 'json'});
    return fetch(url, requestOptions)
      .then(this.handleStatus.bind(this))
      .then(this.handleResponse);
  },

  handleStatus: function(response) {
    // const tested = localStorage.getItem('tested');
    // if (!tested) {
    //   response = JSON.parse(JSON.stringify(response));
    //   response.status = 401
    //   response.ok = false
    //   localStorage.setItem('tested', 'true');
    // }
    if (!response.ok) {
      // store.dispatch(changeStatusCode(response.status));
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        if (router.currentRoute.name !== 'login') {
          console.warn('401');
          router.push({ name: 'login', query: { next: router.currentRoute.fullPath }});
        }
      } else if (response.status === 404) {
        store.dispatch('server/setStatusCode', 404);
      }
      return this.handleResponse(response).then(data => {
        return Promise.reject(data);
      });
    } else {
      // console.log(router.currentRoute.name);
      return response;
    }
  },

  handleResponse: function(response) {
    return response.text().then(text => {
      let data = text;
      try {
        data = JSON.parse(text);
      } catch (e) {
        console.log(e);
      }

      return data;
    });
  },
};
