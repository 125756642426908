<template>
  <div>
    <base-input
      v-if="field.type === 'integer'"
      :label="field.label"
      type="number"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
    <base-input
      v-else-if="field.type === 'decimal'"
      :label="field.label"
      type="number"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :step="field.step || 0.1"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
    <base-input
      v-else-if="field.type === 'date'"
      :label="field.label"
      type="date"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
    <base-input
      v-else-if="field.type === 'time'"
      :label="field.label"
      type="time"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :step="field.step || 900"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
    <base-select
      v-else-if="field.type === 'choice'"
      :label="field.label"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :options="field.choices"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
      @change="changeValue"
    />
    <base-file
      v-else-if="field.type === 'file'"
      :label="field.label"
      :required="field.required===true"
      :file_uuid="value"
      :filename="data[field.verbose_name]"
      :column="field"
      :data="data"
      :error="error"
      @preview="hanldePreview"
      @input="updateValue"
    />
    <base-textarea
      v-else-if="field.type==='text'"
      :label="field.label"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
    <base-checkbox
      v-else-if="field.type==='boolean'"
      :label="field.label"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
    <base-autocomplete
      v-else-if="field.type === 'autocomplete'"
      :label="field.label"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      :multiple="field.multiple===true"
      :data_source="field.data_source"
      :field_for_init="field.field_for_init"
      @input="updateValue"
    />
    <!-- "field.type === 'string'" -->
    <base-input
      v-else
      :label="field.label"
      :maxlength="field.max_length"
      :readonly="field.read_only"
      :required="field.required===true"
      :rules="{required: field.required===true}"
      :help_text="field.help_text"
      :column="field"
      :data="data"
      :value="value"
      :error="error"
      @input="updateValue"
    />
  </div>
</template>
<script>
export default {
  props: {
    field: { type: Object, default: () => ({}), required: true },
    data: { type: Object, default: () => ({}) },
    value: { type: [String, Number, Boolean] },
    error: { type: [String, Array], default: null },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value, this.field.name);
    },
    hanldePreview(uuid, context) {
      if (this.field.handle_download) {
        return this.field.handle_download(uuid, context);
      }
    },
    changeValue(value) {
      this.$emit("change", value, this.field.name);
    },
  }
}
</script>
