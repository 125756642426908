<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>
    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <h3 class="mb-0">{{pageName}}</h3>
            </b-card-header>
            <b-card-body>
              <!-- your content here -->
              <router-view></router-view>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { common } from '../../utils';
export default {
  computed: {
    pageName() {
      const { name } = this.$route;
      return common.getPageName(name);
    },
  }
}
</script>