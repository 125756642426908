<template>
    <b-form @submit.prevent>
        <validation-observer ref="observer">
            <b-row>
                <b-col cols="12">
                    <ControlCreator :field='get_field_by_name(`assets_type`)' :error='get_error_by_name(`assets_type`)' v-model="value[`assets_type`]" :data="value"
                                    @input="updateValue"/>
                </b-col>
            </b-row>
            <b-row v-show="assetsItem.hardware_information">
                <b-col cols="12">
                    <div>
                        <label class="form-control-label">ハードウェア情報</label>
                    </div>
                    <pre style="color:#8898aa;">{{ assetsItem.hardware_information }}</pre>
                </b-col>
            </b-row>
            <b-row v-show="parseInt(assetsItem.validity_period) >= 0">
                <b-col cols="12">
                    <div>
                        <label class="form-control-label">有効期限</label>
                    </div>
                    <div style="font-size: 0.875rem; margin-bottom: 1rem;">
                        <span v-if="parseInt(assetsItem.validity_period) === 0">期限なし</span>
                        <span v-else>{{ assetsItem.validity_period }}ヶ月</span>
                    </div>
                </b-col>
            </b-row>
            <b-row v-show="assetsItem.software_list && assetsItem.software_list.length > 0">
                <b-col cols="12">
                    <div>
                        <label class="form-control-label">ソフトウェア</label>
                    </div>
                    <b-col sm="12" md="6" lg="6">
                        <template v-for="(item, index) in assetsItem.software_list">
                            <b-form-checkbox
                                :key="index"
                                :value="item.software_id"
                                v-model="selectedSoftware"
                                @change="updateSelectedSoftware(item.software_id, item.software_name, item.software_validity_period)"
                            >
                                <span v-if="parseInt(item.software_validity_period) > 0">{{ item.software_name+'（'+item.software_validity_period+'ヶ月）' }}</span>
                                <span v-else>{{ item.software_name }}</span>
                            </b-form-checkbox>
                        </template>
                    </b-col>
                </b-col>
            </b-row>
            <b-row class="display-none">
                <b-col cols="12">
                    <ControlCreator :field='get_field_by_name(`expiration_date`)' :error='get_error_by_name(`expiration_date`)' v-model="value[`expiration_date`]" :data="value"
                                    @input="updateValue" />
                </b-col>
            </b-row>
            <b-row class="display-none">
                <b-col cols="12">
                    <ControlCreator :field='get_field_by_name(`software`)' :error='get_error_by_name(`software`)' v-model="value[`software`]" :data="value"
                                    @input="updateValue" />
                </b-col>
            </b-row>
            <b-row class="display-none">
                <b-col cols="12">
                    <ControlCreator :field='get_field_by_name(`software_id`)' :error='get_error_by_name(`software_id`)' v-model="value[`software_id`]" :data="value"
                                    @input="updateValue" />
                </b-col>
            </b-row>
            <b-row class="display-none">
                <b-col cols="12">
                    <ControlCreator :field='get_field_by_name(`asset_id`)' :error='get_error_by_name(`asset_id`)' v-model="value[`asset_id`]" :data="value"
                                    @input="updateValue" />
                </b-col>
            </b-row>
            <b-row v-if="assetsItem.inventory === false" style="margin-top: 3rem;">
                <b-col cols="12" style="text-align: center; color:#721c24;">
                    該当資材の在庫がありませんので、「申請」ボタンを押すと資材購入申請から行います。
                </b-col>
            </b-row>
        </validation-observer>
    </b-form>
</template>

<script>
    import {ajax, common, config} from '../../utils';
    import ControlCreator from './ControlCreator';

    export default {
        name: 'assets-form',
        components: {
            ControlCreator,
        },
        props: {
            schema: { type: Array, default: () => [], required: true },
            layout: { type: Array, default: null },
            value: { type: Object, default: () => ({}) },
            error: { type: Object, default: () => ({}) },
            workflow_code: { type: String, default: '' },
        },
        data() {
            return {
                assetsData: [],
                assetsItem: {},
                selectedSoftwareNames: [],
                selectedSoftwareIds: [],
            };
        },
        created() {
            this.getAssetsInfo();
            this.assetsItem = this.value['assets_type'] ? this.assetsData.find(item => item.id === parseInt(this.value['assets_type'])) || {} : {};
        },
        computed: {
            selectedSoftware: {
                get() {
                    return this.value[`software_id`]
                        ? this.value[`software_id`].split(",").map((id) => Number(id))
                        : [];
                },
                set(newValue) {
                    this.value[`software_id`] = newValue.join(",");
                },
            },
        },
        methods: {
            get_field_by_name(name) {
                return this.schema.find(i => i.name === name);
            },
            get_error_by_name(name) {
                if (this.error && name in this.error) {
                    const errors = this.error[name];
                    if (Array.isArray(errors) && errors.length > 0) {
                        return errors[0]
                    } else {
                        return errors;
                    }
                } else {
                    return null;
                }
            },
            getAssetsInfo() {
                const vm = this;
                ajax.fetchGet(common.formatStr(config.api.assets.assets_lend)).then(data => {
                    vm.assetsData = data;
                    if (vm.value['assets_type']) {
                        vm.assetsData.forEach((item, index) => {
                            if (vm.assetsData[index].id === parseInt(vm.value['assets_type'])) {
                                vm.assetsItem = vm.assetsData[index];
                                vm.$emit('inventory', vm.assetsItem.inventory);
                            }
                        });
                    }
                    vm.selectedSoftwareNames = vm.value['software'] ? vm.value['software'].split(',').map((name) => name) : [];
                    vm.selectedSoftwareIds = vm.value['software_id'] ? vm.value['software_id'].split(',').map((id) => Number(id)) : [];
                });
            },
            updateValue(value, name) {
                const data = common.clone(this.value);
                Object.assign(data, {[name]: value});
                this.$emit("input", data);

                this.assetsItem = {};
                this.selectedSoftwareNames = [];
                this.selectedSoftwareIds = [];
                this.value['software'] = '';
                this.value['software_id'] = '';
                this.value['expiration_date'] = 0;
                let is_inventory = true;
                this.assetsData.forEach((item, index) => {
                    if (this.assetsData[index].id === parseInt(value)) {
                        this.assetsItem = this.assetsData[index];
                        this.value['expiration_date'] = this.assetsItem.validity_period;
                        is_inventory = this.assetsItem.inventory;
                        this.$emit('inventory', is_inventory);
                    }
                });
            },
            validate() {
                return this.$refs.observer.validate()
            },
            updateSelectedSoftware(softwareId, softwareName, softwareValidityPeriod) {
                if (softwareValidityPeriod && softwareValidityPeriod > 0) {
                    softwareName = softwareName + '（'+softwareValidityPeriod+'ヶ月）';
                }
                if (this.selectedSoftwareNames.indexOf(softwareName) === -1) {
                    this.selectedSoftwareNames.push(softwareName);
                } else {
                    this.selectedSoftwareNames.splice(this.selectedSoftwareNames.indexOf(softwareName), 1);
                }
                if (this.selectedSoftwareIds.indexOf(softwareId) === -1) {
                    this.selectedSoftwareIds.push(softwareId);
                } else {
                    this.selectedSoftwareIds.splice(this.selectedSoftwareIds.indexOf(softwareId), 1);
                }

                this.value['software'] = this.selectedSoftwareNames.join(',');
                this.selectedSoftware = this.selectedSoftwareIds;
            },
        }
    }
</script>

<style scoped>
    .display-none {
        display: none;
    }
</style>