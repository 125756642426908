<template>
  <div class="bg-default" style="height: 100%;">
    <!-- Header -->
    <div class="header bg-gradient-success py-6">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white" v-if="larkLoginFailed">ログイン失敗しました</h1>
              <h1 class="text-white" v-else>ようこそ!</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card v-if="(ticket && !status.errors) || larkLoging" no-body class="bg-secondary border-0 mb-0">
            <b-card-header class="bg-transparent pt-9 pb-9"  >
              <div class="btn-wrapper text-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
                ログイン中...
              </div>
            </b-card-header>
          </b-card>
          <b-card v-else-if="ticket && status.errors" no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-6 py-lg-6">
              <div class="text-center text-muted mb-4">
                {{ errors }}
              </div>
              <div class="text-center">
                <base-button type="primary" class="mt-5 mb-4" @click="casReLogin">再ログイン</base-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { common } from '../../utils';
import { casLogin, casLogout } from '../../utils/config';

export default {
  data() {
    return {
      larkLoging: false,
      larkLoginFailed: false
    };
  },
  computed: {
    ...mapState('account', ['status']),
    ticket: function() {
      return this.$route.query ? this.$route.query.ticket : null;
    },
    errors: function() {
      if (this.status.errors) {
        if (this.status.errors.detail) {
          return this.status.errors.detail
        } else {
          return this.status.errors;
        }
      }
      return null;
    },
    service: function() {
      return common.addUrlParameter(`${window.location.origin}/login`, this.$route.query)
    }
  },
  created: async function() {
    if (window.h5sdk) {
      window.h5sdk.error(err => {
          console.error('h5sdk error:', JSON.stringify(err));
      });
    }
    document.title = "ログイン";
    if (this.ticket) {
      this.casLogin({ service: this.service, ticket: this.ticket });
    } else {
      const res = await this.getLarkCode()
      if (res) {
        if (res?.code) {
          // lark login success
          this.larkLogin({ 
            service: this.service, 
            code : res.code, 
            success: ()=>{
              this.larkLoging = false
            }, fail: ()=>{
              this.larkLoginFailedNotice()
            }
          });
        } else {
          this.larkLoginFailedNotice()
        }
      } else {
        // lark login failed
        window.location.assign(`${casLogin}?service=${encodeURIComponent(this.service)}`)
      }
    }
  },
  methods: {
    ...mapActions('account', ['casLogin', 'larkLogin']),
    casReLogin() {
      window.location.assign(`${casLogout}?service=${encodeURIComponent(this.service)}`)
    },
    async getLarkCode() {
      const promise = new Promise((resolve) => {
        if (!window.h5sdk) {
          resolve(false)
        }
        this.larkLoging = true
        window.h5sdk.ready(() => {
          console.log('window.h5sdk.ready')
          console.log('url:', window.location.href)
          console.log(window.tt)
          window.tt.requestAuthCode({
            appId: 'cli_a6df85f0b378d02e',
            // Success callback
            success(res) {
              console.log('getAuthCode succeed')
              console.log(`authCode:`, res)
              // alert(`authCode: ${JSON.stringify(res)}`)
              resolve(res)
            },
            // Failure callback
            fail(err) {
              console.log(`getAuthCode failed, err:`, JSON.stringify(err))
              // alert(`getAuthCode failed, err: ${JSON.stringify(err)}`)
              resolve({ code: null })
            },
          })
        })
      })
      return promise
    },
    larkLoginFailedNotice() {
      alert('ログイン失敗しました')
      this.larkLoging = false
      this.larkLoginFailed = true
    }
  }
};
</script>
