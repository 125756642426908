<script>
export default {
  success: function(message) {
    window.vm.$bvToast.toast(message, {
      title: '成功：',
      variant: 'success',
      solid: true,
    });
  },
  error: function(message) {
    window.vm.$bvToast.toast(message, {
      title: 'エラー：',
      variant: 'danger',
      solid: true,
    });
  },
  errorFromApi: function(errors) {
    if ('detail' in errors) {
      this.error(errors.detail);
    } else {
      for (let key in errors) {
        if (key !== "status_code" && Array.isArray(errors[key])) {
          errors[key].map(message => {
            this.error(message);
          })
        }
      }
    }
  },
}
</script>