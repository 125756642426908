<template>
  <div class="b-stepper" role="tablist">
    <b-card no-body v-for="(step, i) in steps" :key="`index${step.key}`">
      <template v-if="step.visible">
        <b-card-header class="p-1 step-header">
          <b-row align-v="center">
            <div
              :aria-expanded="visible ? 'true' : 'false'"
              aria-controls="'accordion-' + i"
              variant="primary"
              pill
              @click="visible = !visible"
              :class="`step-number align-items-center justify-content-center ${step.disabled ? '' : 'bg-primary'} ${visible ? '' : 'collapsed'} text-white`"
            >
              <span>{{ i + 1 }}</span>
            </div>
            <div class="step-name" align-v="center">{{ step.name }}</div>
            <b-col v-if="!step.disabled" class="text-right">
              <div class="action">
                <!-- <b-icon icon="plus"
                  class="rounded-circle bg-info p-2"
                  @click="insertNode(i)"
                ></b-icon> -->
                <b-icon icon="trash"
                  class="rounded-circle bg-danger p-2"
                  variant="light"
                  @click="deleteNode(i)"
                ></b-icon>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse :id="`accordion-${i}`" v-model="visible" class="step-content">
          <component
            v-if="!step.disabled"
            v-bind="step.props"
            @change="step.handleChange"
            :is="stepComponent(i)"
          />
        </b-collapse>
      </template>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "b-stepper",
  props: {
    steps: { type: Array, default: () => [] },
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    stepComponent(i) {
      return this.steps[i].component;
    },
    deleteNode(i) {
      this.$emit('delete', i)
    },
    insertNode(i) {
      this.$emit('insert', i)
    }
  },
}
</script>