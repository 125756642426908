<template>
  <validation-provider :rules="rules" :name="label" v-bind="$attrs" v-slot="{errors, valid, invalid, validated}">
    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}
          <span v-if="required || rules.required" style="color: red;">（*）</span>
        </label>
      </slot>
      <div
        class="custom-control custom-radio"
        :class="[inlineClass, { disabled: disabled }]"
        style="padding: 0"
      >
        <b-form-radio-group
          :checked="value"
          value-field="value"
          text-field="text"
          :options="options"
          class="mb-3"
          :name="name"
          :disabled="disabled"
          @input="updateValue"
          :class="[{'is-valid': valid && validated && successMessage}, {'is-invalid': invalid && validated}, inputClasses]"
        ></b-form-radio-group>
      </div>
      <div v-if="help_text" class="help_text">
        {{ help_text }}
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{successMessage}}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
          {{ errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<script>
  export default {
    name: 'base-checkbox',
    props: {
      inputClasses: {
        type: String,
        description: "Input css classes"
      },
      labelClasses: {
        type: String,
        description: "Input label css classes",
        default: "form-control-label"
      },
      label: {
        type: String,
        description: "Input label (text before input)"
      },
      name: {
        type: [String, Number],
        description: 'Radio label'
      },
      required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)"
      },
      disabled: {
        type: Boolean,
        description: 'Whether radio is disabled'
      },
      value: {
        type: Boolean,
        description: 'Radio value'
      },
      inline: {
        type: Boolean,
        description: 'Whether radio is inline'
      },
      rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
      },
      help_text: { type: String, default: null },
    },
    data() {
      const options = [];
      if (this.required !== true) {
        options.push({ text: '未選択', value: null });
      }
      options.push({ text: 'はい', value: true });
      options.push({ text: 'いいえ', value: false });
      return {
        options: options,
      };
    },
    computed: {
      inlineClass() {
        if (this.inline) {
          return `form-check-inline`;
        }
        return '';
      }
    },
    methods: {
      updateValue(value) {
        this.$emit("input", value);
      },
    }
  };
</script>
