import { userService } from './user.service'
import router from '../router/index'
import { common } from '../utils';

const state = {
  user: {},
  status: { loggedIn: false, errors: null, },
  statistics: {},
  organizations: [],
  organizationsMap:{},
  notifications: [],
  sidebar: false,
};

const actions = {
  login({commit}, {username, password}) {
    commit('loginRequest', { username });
    return userService.login(username, password)
      .then(
        user => {
          commit('loginSuccess', user);
          router.push({ name: 'root' });
        },
        errors => {
          commit('loginFailure', errors);
          return Promise.reject(errors);
        }
      );
  },
  logout({ commit }) {
    userService.logout();
    commit('logout');
    router.push({ name: 'login',});
  },
  casLogin({commit}, {service, ticket}) {
    commit('loginRequest', {});
    return userService.casLogin(service, ticket)
      .then(user => {
        commit('loginSuccess', user);
        const qs = common.parseQueryString((new URL(service)).search);
        if (qs && qs.next) {
          router.push({ path: qs.next });
        } else {
          router.push({ name: 'root' });
        }
      }).catch(error => {
        commit('loginFailure', error);
        // dispatch('alert/error', error, { root: true });
      });
  },
  larkLogin({commit}, {service, code, success, fail}) {
    console.log('larkLogin', service, code)
    commit('loginRequest', {});
    return userService.larkLogin(code)
      .then(user => {
        commit('loginSuccess', user);
        const qs = common.parseQueryString((new URL(service)).search);
        if (qs && qs.next) {
          router.push({ path: qs.next });
        } else {
          router.push({ name: 'root' });
        }
        if (success) {
          success();
        }
      }).catch(error => {
        commit('loginFailure', error);
        if (fail) {
          fail(error);
        }
        // dispatch('alert/error', error, { root: true });
      });
  },
  async casLogout({ commit }) {
    const data = await userService.casLogout();
    commit('logout');
    if (data && data.redirect) {
      window.location.assign(data.redirect)
    } else {
      router.push({ name: 'login',});
    }
  },
  login_openid({ commit }, { access, refresh }) {
    localStorage.setItem('token', JSON.stringify(access));
    localStorage.setItem('refresh', JSON.stringify(refresh));
    commit('loginSuccess', { access });
    router.push({ name: 'root' });
  },
  getMe({ dispatch, commit }) {
    return userService.getMe().then(data => {
      commit('getMeSuccess', data)
    }).catch(errors => {
      dispatch('server/setServerError', errors.detail, { root:true });
      commit('getMeFailure', errors)
    });
  },
  getStatistics({ commit }) {
    userService.getStatistics().then(data => {
      commit('getStatSuccess', data);
    });
  },
  getOrganizations({ commit }) {
    userService.getOrganizations().then(data => {
      commit('getOrgSuccess', data);
      const resultMap = new Map(); // 使用 Map 对象
      data.forEach(item => {
          let departmentName = item.name;
          let departmentId = item.id;
          let parentDepartmentId = item.parent_id;
          if (parentDepartmentId == null) {
              resultMap.set(departmentId, departmentName); // 使用 set 方法添加键值对
          } else {
              let parentHierarchy = resultMap.get(parentDepartmentId); // 使用 get 方法获取值
              if (parentHierarchy != null) {
                  // 构建当前部门的层级名称
                  let departmentHierarchy = parentHierarchy + "/" + departmentName;
                  resultMap.set(departmentId, departmentHierarchy); // 使用 set 方法添加键值对
              }
          }
      });
      console.log('resultMap', resultMap)
      commit('getOrgMapSuccess', resultMap)
    });
  },
  getNotifications({ commit }) {
    userService.getNotifications().then(data => {
      commit('getNotificationSuccess', data);
    });
  },
  readNotification({ commit }, { notification_id, is_read }) {
    userService.readNotification(notification_id, is_read).then(data => {
      commit('getNotificationRead', data);
    });
  },
  setAvatar({ commit }, url) {
    commit('setAvatarSuccess', url);
  },
  addFirebaseDevice({ commit }, token) {
    commit('addDeviceSuccess', token);
  },
  showSidebar({ commit }, show) {
    commit('showSidebarSuccess', show);
  }
}

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true, errors: null };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true, errors: null };
    state.user = user;
  },
  loginFailure(state, errors) {
    state.status = { loggingIn: false, errors };
    state.user = {};
  },
  logout(state) {
    state.status = {};
    state.user = {};
  },
  getMeSuccess(state, data) {
    state.user = Object.assign({}, state.user, data);
  },
  getMeFailure(state, errors) {
    state.user = null;
    state.error = errors;
  },
  getStatSuccess(state, data) {
    state.statistics = data;
  },
  getOrgSuccess(state, data) {
    state.organizations = data;
  },
  getOrgMapSuccess(state, data) {
    state.organizationsMap = data
  },
  getNotificationSuccess(state, data) {
    state.notifications = data;
  },
  setAvatarSuccess(state, url) {
    state.user.me.avatar = url;
  },
  addDeviceSuccess(state, token) {
    state.user.me.devices.push(token);
  },
  showSidebarSuccess(state, show) {
    state.sidebar = show;
  },
  getNotificationRead(state, data) {
    const idx = state.notifications.findIndex(i => i.id === data.id);
    state.notifications.splice(idx, 1, data);
  },
}

const getters =  {
  full_org_name(state) {
    if (state.user && state.user.organizations) {
      return state.user.organizations.map(o => o.org_name).join(' - ')
    } else {
      return null;
    }
  },
  positions_name(state) {
    if (state.user && state.user.positions) {
      return state.user.positions.map(o => `${o.org_name} - ${o.name}${o.is_part_time ? '(兼任)' : ''}`)
    } else {
      return null;
    }
  },
  isStaff: (state) => {
    if (state.user && state.user.me) {
      return state.user.me.is_staff;
    } else {
      return false;
    }
  },
  hasPerm: (state) => (perm) => {
    if (state.user && state.user.me) {
      if (state.user.me.is_superuser) {
        return true;
      }
      const userPerms = state.user.perms;  // ユーザーが持っているすべての権限
      if (!userPerms || !Array.isArray(userPerms) || userPerms.length === 0) {
        return false;
      } else if (Array.isArray(perm)) {
        // 複数の権限の中に1つでもあればOK
        for (let p of perm) {
          if (userPerms.indexOf(p) >= 0) {
            return true;
          }
        }
        return false;
      } else {
        return userPerms.indexOf(perm) >= 0;
      }
    } else {
      return false;
    }
  },
  sidebar: (state) => {
    return state.sidebar;
  },
  userNotifications: (state) => {
    return state.notifications;
  },
  getOrganizationchoices: (state) => {
    if (state.organizations) {
      return state.organizations.map(o => ({ value: o.id, text: o.name, parent: o.parent_id }));
    } else {
      return [];
    }
  },
  getOrganizationName: (state) => (orgId) => {
    const org = state.organizations.find(o => o.id === orgId)
    if (org) {
      return org.name;
    } else {
      return null;
    }
  },
}


export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};